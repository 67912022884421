import React from 'react'
import { Link } from 'react-router-dom'

function Header({ pageName }) {
  return (
    <div className="border-b w-full h-16 p-4 flex justify-between items-center">
      <span className="font-bold text-2xl">{pageName}</span>
      <Link
        to="/account"
        className="relative inline-flex items-center justify-center w-8 h-8 text-white rounded-full bg-indigo-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </Link>
    </div>
  )
}

export default Header
