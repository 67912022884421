import React from 'react'

const ReusableAlert = ({ type, title, content }) => {
  let icon, colorClasses

  switch (type) {
    case 'success':
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      )
      colorClasses = 'border-emerald-100 bg-emerald-50 text-emerald-500'
      break
    case 'warning':
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      )
      colorClasses = 'border-amber-100 bg-amber-50 text-amber-500'
      break
    case 'info':
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
        />
      )
      colorClasses = 'border-cyan-100 bg-cyan-50 text-cyan-500'
      break
    case 'danger':
      icon = (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      )
      colorClasses = 'border-pink-100 bg-pink-50 text-pink-500'
      break
    default:
      break
  }

  return (
    <div
      className={`flex w-full items-start gap-4 rounded border px-4 py-3 text-sm my-2 ${colorClasses}`}
      role="alert"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="1.5"
        role="graphics-symbol"
        aria-labelledby="title desc"
      >
        {icon}
      </svg>
      <div>
        {title && <h3 className="mb-2 font-semibold">{title}</h3>}
        <p>{content}</p>
      </div>
    </div>
  )
}

export default ReusableAlert
