import React, { useState } from 'react'

const ReusableInput = ({
  id,
  placeholder,
  disabled,
  value,
  onChange,
  helperText,
}) => {
  const baseStyles =
    'peer relative h-10 w-full rounded px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all'
  const activeStyles =
    'focus:border-indigo-500 focus:outline-none focus-visible:outline-none'
  const disabledStyles = 'cursor-not-allowed bg-slate-50 text-slate-400'

  return (
    <div className="relative my-6">
      <input
        id={id}
        type="text"
        name={id}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={`${baseStyles} border border-slate-200 ${
          !disabled ? activeStyles : ''
        } ${disabled ? disabledStyles : ''}`}
      />
      <label
        htmlFor={id}
        className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-indigo-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
      >
        {placeholder}
      </label>
      <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
        <span>{helperText}</span>
      </small>
    </div>
  )
}

export default ReusableInput
