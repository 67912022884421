import React, { useEffect, useState } from "react";
import { appService } from "../../services/app.service";
import { useLocation, useNavigate } from "react-router";
import CustomerUsers from "./CustomerUsers";
import CustomerInvoices from "./CustomerInvoices";
import ReusableSpinner from "../../components/ui/loading/ReusableSpinner";

function CustomerPage() {
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [customerContact, setCustomerContact] = useState(null);
  const [customerAccount, setCustomerAccount] = useState(null);
  const [card, setCard] = useState(null);

  const pattern = /\/customers\/(\d+)/;
  const location = useLocation();
  const navigate = useNavigate();

  const getCustomer = async () => {
    const customer = await appService.getCustomers(
      location.pathname.match(pattern)[1]
    );
    setCustomer(customer.data);

    const user = await appService.getUser(
      customer.data.customerId,
      customer.data.ownerId
    );
    setCustomerContact(user);
    const account = await appService.getAccount(customer.data.customerId);

    setCustomerAccount(account);
    const card = await appService.getCardInfo(customer.data.customerId);
    setCard(card);
    setLoading(false);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[calc(100%-4rem)] w-full p-4">
          <ReusableSpinner size="large" />
        </div>
      ) : (
        <div className="flex h-[calc(100%-4rem)] w-full">
          <div className="flex flex-col 2xl:flex-row w-full h-full">
            <div id="sidebar" className="flex flex-col w-full 2xl:w-1/4 h-full border-r ">
              <button
                className="m-4 flex items-center gap-2 text-indigo-500"
                onClick={() => {
                  navigate("/customers");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Back to customers
              </button>
              <div className="w-full pt-2">
                <div className="py-4 flex flex-col font-normal gap-1 px-4">
                  <p className="font-semibold pb-2 flex gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                      />
                    </svg>
                    Customer Contact
                  </p>
                  <div className="flex w-full gap-4 justify-between pr-4">
                    <div>
                      <p className="text-sm text-gray-700 font-semibold">
                        Name
                      </p>
                      <p className="text-sm font-light">
                        {customerContact?.userName}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Email
                      </p>
                      <p className="text-sm font-light text-blue-500">
                        <a
                          href={`mailto:${customerContact?.email}`}
                          target="blank"
                          className="text-right"
                        >
                          {customerContact?.email}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-4 flex flex-col font-normal gap-1 px-4">
                  <p className="font-semibold pb-2 flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                      />
                    </svg>
                    Billing Information
                  </p>
                  <div className="flex w-full justify-between pr-4">
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Address
                      </p>
                      <p className="text-sm font-light">
                        <p
                          className={`${
                            customer?.billingAddress?.line1 ? "flex" : "hidden"
                          } text-sm`}
                        >
                          {customer?.billingAddress?.line1}
                        </p>
                        <p
                          className={`${
                            customer?.billingAddress?.line2 ? "flex" : "hidden"
                          } text-sm`}
                        >
                          {customer?.billingAddress?.line2}
                        </p>
                        <span className="flex">
                          <p
                            className={`${
                              customer?.billingAddress?.postal_code
                                ? "flex"
                                : "hidden"
                            } text-sm`}
                          >
                            {customer?.billingAddress?.postal_code},&nbsp;
                          </p>
                          <p
                            className={`${
                              customer?.billingAddress?.city ? "flex" : "hidden"
                            } text-sm`}
                          >
                            {customer?.billingAddress?.city}
                          </p>
                        </span>
                        <p
                          className={`${
                            customer?.billingAddress?.state ? "flex" : "hidden"
                          } text-sm`}
                        >
                          {customer?.billingAddress?.state}
                        </p>
                        <p
                          className={`${
                            customer?.billingAddress?.country ? "flex" : "hidden"
                          } text-sm`}
                        >
                          {customer?.billingAddress?.country}
                        </p>
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Invoicing email
                      </p>
                      <p className="text-sm font-light">
                        {customer?.invoicingEmail}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-4 flex flex-col font-normal gap-1 px-4">
                  <p className="font-semibold pb-2 flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    Subscription
                  </p>
                  <div className="flex w-full gap-4 justify-between pr-4">
                    <div>
                      <p className="text-sm text-gray-700 font-semibold">
                        License
                      </p>
                      <p className="text-sm font-light">
                        {customerAccount?.plan?.currentPlan?.licenseName || "None"}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Status
                      </p>
                      <p className="text-sm font-light">
                        { customer.subscriptionStatus && 
                          customer?.subscriptionStatus
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full gap-4 justify-between pr-4">
                    <div>
                      <p className="text-sm text-gray-700 font-semibold">
                        Billing
                      </p>
                      <p className="text-sm font-light">
                        {customerAccount?.accountDetails.billing
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Billing Period
                      </p>
                      <p className="text-sm font-light">
                        { customerAccount?.plan?.currentPlan?.billing &&
                          customerAccount?.plan?.currentPlan?.billing
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full gap-4 justify-between pr-4">
                    <div className="">
                      <p className="text-sm text-gray-700 font-semibold">
                        Period End
                      </p>
                      <p className="text-sm font-light">
                        {customerAccount?.plan?.currentPlan?.periodEnd}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Next Invoice
                      </p>
                      <p className="text-sm font-light">
                        {customerAccount?.plan?.currentPlan?.nextInvoicePrice}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-4 flex flex-col font-normal gap-1 px-4">
                  <p className="font-semibold pb-2 flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                      />
                    </svg>
                    Card Information
                  </p>
                  <div className="flex w-full gap-4 justify-between pr-4">
                    <div>
                      <p className="text-sm text-gray-700 font-semibold">
                        Number
                      </p>
                      <p className="text-sm font-light">
                        {card?.cardNumber ? card?.cardNumber : "N/A"}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold">
                        Brand
                      </p>
                      <p className="text-sm font-light">
                        {card?.brand ? card?.brand : "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full gap-4 justify-between pr-4">
                    <div>
                      <p className="text-sm text-gray-700 font-semibold">
                        Expires
                      </p>
                      <p className="text-sm font-light">
                        {card?.expires ? card?.expires : "N/A"}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-sm text-gray-700 font-semibold"></p>
                      <p className="text-sm font-normal"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="main" className="w-full 2xl:w-3/4 h-full">
              <div className="flex flex-col h-1/2 ">
                <CustomerUsers customer={customer} />
              </div>
              <div className="flex flex-col h-1/2 ">
                <CustomerInvoices customer={customer} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerPage;
