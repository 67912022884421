import React, { useEffect, useState } from "react";
import ReusableSpinner from "../../components/ui/loading/ReusableSpinner";
import ReusableDropdown from "../../components/ui/dropdowns/ReusableDropdown";
import { appService } from "../../services/app.service";
import ReusableSelect from "../../components/ui/inputs/ReusableSelect";
import { forEach } from "lodash";
import AreaWidget from "../../components/widgets/area/AreaWidget";
import ReusableCheckboxDropdown from "../../components/ui/dropdowns/ReusableCheckboxDropdown";
import { DateTime } from "luxon";
import KpiWidget from "../../components/widgets/kpi/KpiWidget";

function Statistics() {
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [nodeOpts, setNodeOpts] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState(null);
  const [vms, setVms] = useState([]);
  const [vmOpts, setVmOpts] = useState([]);
  const [selectedVms, setSelectedVms] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState("day");
  const [selectedCalculation, setSelectedCalculation] = useState("AVERAGE");
  const [nodeStatistics, setNodeStatistics] = useState(null);
  const [vmStatistics, setVmStatistics] = useState(null);
  const [kpiData, setKpiData] = useState({
    cpu: {
      bigLabel: {
        value: null,
        string: null
      },
      smallLabel: {
        value: null,
        string: null
      }
    },
    memory: {
      bigLabel: {
        value: null,
        string: null
      },
      smallLabel: {
        value: null,
        string: null
      }
    },
    net: {
      bigLabel: {
        value: null,
        string: null
      },
      smallLabel: {
        value: null,
        string: null
      }
    },
  })
  
  const [nodeSeries, setNodeSeries] = useState({
    cpu: [],
    memory: [],
    disk: [],
    net: [],
  });
  
  const [vmSeries, setVmSeries] = useState({
    cpu: [],
    memory: [],
    disk: [],
    net: [],
  });

  const [series, setSeries] = useState({
    cpu: [],
    memory: [],
    disk: [],
    net: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const nodes = await appService.getNodes();
    setNodes(nodes);

    const vmPromises = nodes.map(async (node) => {
      const nodeVms = await appService.getVMs(node.node);
      for (let i = 0; i < nodeVms.length; i++) {
        nodeVms[i].node = node.node;
      }
      return nodeVms;
    });

    const vms = await Promise.all(vmPromises);
    const flattenedVms = vms.filter((vmArray) => vmArray !== undefined).flat();
    setVms(flattenedVms);

    setLoading(false);
  };

  const getNodeStatistics = async () => {
    setDataLoading(true);
    let nodeStatistics = {};
    for (let i = 0; i < nodes.length; i++) {
      const data = await appService.getNodeStatistics(
        nodes[i].node,
        selectedTimeframe,
        selectedCalculation
      );
      nodeStatistics[nodes[i].node] = data;
    }

    setNodeStatistics(nodeStatistics);
  };

  const getVMStatistics = async () => {
    setDataLoading(true);
    let vmStatistics = {};
    for (let i = 0; i < vms.length; i++) {
      const data = await appService.getVMStatistics(
        vms[i].node,
        vms[i].vmid,
        selectedTimeframe,
        selectedCalculation
      );
      vmStatistics[vms[i].name] = data;
    }
    setVmStatistics(vmStatistics);
  };

  useEffect(() => {
    if (nodes.length > 0) {
      getNodeStatistics();
    }
  }, [nodes]);

  useEffect(() => {
    if (vms.length > 0) {
      getVMStatistics();
    }
  }, [vms]);

  const handleNodeChange = (selectedOptions) => {
    setSelectedNodes(selectedOptions);
  };

  const handleVMChange = (selectedOptions) => {
    setSelectedVms(selectedOptions);
  };

  const handleTimeFrameChange = (value) => {
    setSelectedTimeframe(value);
  };

  const handleCalculationChange = (value) => {
    setSelectedCalculation(value);
  };

  useEffect(() => {
    getNodeStatistics();
    getVMStatistics();
  }, [selectedCalculation, selectedTimeframe]);

  useEffect(() => {
    const newArray = [];
    if (nodes.length > 0) {
      nodes.forEach((item) => {
        newArray.push({
          key: item.node,
          label: item.node.toUpperCase(),
        });
      });
      setNodeOpts(newArray);
    }
  }, [nodes]);

  useEffect(() => {
    const newArray = [];
    if (vms.length > 0) {
      vms.forEach((item) => {
        newArray.push({
          key: item.name,
          label: item.name.toUpperCase(),
        });
      });
      setVmOpts(newArray);
    }
  }, [vms]);

  useEffect(() => {
    let timeGrouping;

    switch (selectedTimeframe) {
      case "hour":
        timeGrouping = "HH:mm";
        break;
      case "day":
        timeGrouping = "dd LLL HH:00";
        break;
      case "year":
        timeGrouping = "yyyy w.WW";
        break;
      default:
        timeGrouping = "dd LLL yyyy";
        break;
    }

    if (nodeStatistics) {
      let nodeSeries = {
        cpu: [],
        memory: [],
        disk: [],
        net: [],
      };

      for (let node = 0; node < nodes.length; node++) {
        const currentNode = nodes[node].node;
        nodeSeries.cpu.push({ name: currentNode.toUpperCase(), data: [] });
        nodeSeries.memory.push({ name: currentNode.toUpperCase(), data: [] });
        nodeSeries.disk.push({ name: currentNode.toUpperCase(), data: [] });
        nodeSeries.net.push({ name: currentNode.toUpperCase(), data: [] });

        const currentNodeStatistics = nodeStatistics[currentNode];

        const groupByTimeframe = (data, timeframe) => {
          return data.reduce((acc, current) => {
            const timestamp = current.time;
            const key = DateTime.fromMillis(timestamp * 1000).toFormat(
              timeframe
            );
            if (!acc[key]) {
              acc[key] = [];
            }
            console.log(current)
            acc[key].push(current);
            return acc;
          }, {});
        };

        const calculateAverage = (dataGroup) => {
          const cpu = dataGroup.reduce(
            (total, current) => total + current.cpu,
            0
          );

          const memory = dataGroup.reduce(
            (total, current) => total + current.memused / current.memtotal,
            0
          );

          const disk = dataGroup.reduce(
            (total, current) => total + current.rootused / current.roottotal,
            0
          );

          const net = dataGroup.reduce(
            (total, current) => total + (current.netin + current.netout) / 1024,
            0
          );

          return {
            cpu: ((cpu / dataGroup.length) * 100).toFixed(2),
            memory: ((memory / dataGroup.length) * 100).toFixed(2),
            disk: ((disk / dataGroup.length) * 100).toFixed(2),
            net: ((net / dataGroup.length) * 100).toFixed(2),
          };
          // return (sum / dataGroup.length).toFixed(2);
        };

        // Group data by the desired timeframe (hourly, daily, etc.)
        const groupedData = groupByTimeframe(
          currentNodeStatistics,
          timeGrouping
        );

        // Calculate the average for each metric within each group
        for (const key in groupedData) {
          if (groupedData.hasOwnProperty(key)) {
            const dataGroup = groupedData[key];
            const average = calculateAverage(dataGroup);
            // Push the average value to the series
            nodeSeries.cpu
              .find((node) => node.name === currentNode.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.cpu,
              });

            nodeSeries.memory
              .find((node) => node.name === currentNode.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.memory,
              });

            nodeSeries.disk
              .find((node) => node.name === currentNode.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.disk,
              });

            nodeSeries.net
              .find((node) => node.name === currentNode.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.net,
              });
          }
        }
      }
      setNodeSeries(nodeSeries);
    }

    if (vmStatistics !== null && Object.keys(vmStatistics).length > 0) {
      let vmSeries = {
        cpu: [],
        memory: [],
        disk: [],
        net: [],
      };

      for (let vm = 0; vm < vms.length; vm++) {
        const currentVm = vms[vm].name;
        vmSeries.cpu.push({ name: currentVm.toUpperCase(), data: [] });
        vmSeries.memory.push({ name: currentVm.toUpperCase(), data: [] });
        vmSeries.disk.push({ name: currentVm.toUpperCase(), data: [] });
        vmSeries.net.push({ name: currentVm.toUpperCase(), data: [] });

        const currentVmStatistics = vmStatistics[currentVm];

        const groupByTimeframe = (data, timeframe) => {
          return data.reduce((acc, current) => {
            const timestamp = current.time;
            const key = DateTime.fromMillis(timestamp * 1000).toFormat(
              timeframe
            );
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(current);
            return acc;
          }, {});
        };

        // Define a function to calculate the average of a metric within a group
        const calculateAverage = (dataGroup) => {
          const cpu = dataGroup.reduce(
            (total, current) => total + current.cpu,
            0
          );

          const memory = dataGroup.reduce(
            (total, current) => total + current.mem / current.maxmem,
            0
          );

          const disk = dataGroup.reduce(
            (total, current) => total + current.disk / current.maxdisk,
            0
          );

          const net = dataGroup.reduce(
            (total, current) => total + (current.netin + current.netout) / 1024,
            0
          );

          return {
            cpu: ((cpu / dataGroup.length) * 100).toFixed(2),
            memory: ((memory / dataGroup.length) * 100).toFixed(2),
            disk: ((disk / dataGroup.length) * 100).toFixed(2),
            net: ((net / dataGroup.length) * 100).toFixed(2),
          };
          // return (sum / dataGroup.length).toFixed(2);
        };

        // Group data by the desired timeframe (hourly, daily, etc.)
        const groupedData = groupByTimeframe(currentVmStatistics, timeGrouping);

        // Calculate the average for each metric within each group
        for (const key in groupedData) {
          if (groupedData.hasOwnProperty(key)) {
            const dataGroup = groupedData[key];
            const average = calculateAverage(dataGroup);
            // Push the average value to the series
            vmSeries.cpu
              .find((vm) => vm.name === currentVm.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.cpu,
              });

            vmSeries.memory
              .find((vm) => vm.name === currentVm.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.memory,
              });

            vmSeries.disk
              .find((vm) => vm.name === currentVm.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.disk,
              });

            vmSeries.net
              .find((vm) => vm.name === currentVm.toUpperCase())
              .data.push({
                x: key, // The timestamp for the group
                y: average.net,
              });
          }
        }
      }
      setVmSeries(vmSeries);
    }

    setDataLoading(false);
  }, [nodeStatistics, vmStatistics]);

  useEffect(() => {
    const newSeries = {
      cpu: [],
      memory: [],
      disk: [],
      net: [],
    };

    if (selectedNodes) {
      const activeNodes = [];

      for (const key in selectedNodes) {
        if (selectedNodes.hasOwnProperty(key)) {
          if (selectedNodes[key]) {
            activeNodes.push(key);
          }
        }
      }

      for (let i = 0; i < activeNodes.length; i++) {
        const node = activeNodes[i];
        const cpuData = nodeSeries.cpu.find(
          (currentNode) => currentNode.name === node.toUpperCase()
        );
        const memoryData = nodeSeries.memory.find(
          (currentNode) => currentNode.name === node.toUpperCase()
        );
        const diskData = nodeSeries.disk.find(
          (currentNode) => currentNode.name === node.toUpperCase()
        );
        const netData = nodeSeries.net.find(
          (currentNode) => currentNode.name === node.toUpperCase()
        );

        newSeries.cpu.push(cpuData);
        newSeries.memory.push(memoryData);
        newSeries.disk.push(diskData);
        newSeries.net.push(netData);
      }
    }

    if (selectedVms) {
      const activeVms = [];

      for (const key in selectedVms) {
        if (selectedVms.hasOwnProperty(key)) {
          if (selectedVms[key]) {
            activeVms.push(key);
          }
        }
      }

      for (let i = 0; i < activeVms.length; i++) {
        const vm = activeVms[i];
        const cpuData = vmSeries.cpu.find(
          (currentVm) => currentVm.name === vm.toUpperCase()
        );
        const memoryData = vmSeries.memory.find(
          (currentVm) => currentVm.name === vm.toUpperCase()
        );
        const diskData = vmSeries.disk.find(
          (currentVm) => currentVm.name === vm.toUpperCase()
        );
        const netData = vmSeries.net.find(
          (currentVm) => currentVm.name === vm.toUpperCase()
        );

        newSeries.cpu.push(cpuData);
        newSeries.memory.push(memoryData);
        newSeries.disk.push(diskData);
        newSeries.net.push(netData);
      }
    }

    setSeries(newSeries);
  }, [selectedNodes, selectedVms, nodeSeries, vmSeries]);

  useEffect(() => {

    const newKpiData = {
      cpu: {
        bigLabel: {
          value: null,
          string: null
        },
        smallLabel: {
          value: null,
          string: null
        }
      },
      memory: {
        bigLabel: {
          value: null,
          string: null
        },
        smallLabel: {
          value: null,
          string: null
        }
      },
      net: {
        bigLabel: {
          value: null,
          string: null
        },
        smallLabel: {
          value: null,
          string: null
        }
      },
    } 
    const calculateAverage = (array) => {
      const sum = array.reduce(
        (total, current) => total + Number(isNaN(current.y) ? 0 : current.y),
        0
      );
      
      const length = array.reduce(
        (total, current) => {
          if (isNaN(current.y)) {
            return total
          } else {
            return total + 1
          }
        },
        0
      );

      return Number(sum / length)
    };

    if (series?.cpu.length > 0) {
      series.cpu.forEach((item) => {
        newKpiData.cpu.bigLabel.value = Number(calculateAverage(item.data).toFixed(2))
        newKpiData.cpu.bigLabel.string = `${newKpiData.cpu.bigLabel.value}%`
        newKpiData.cpu.smallLabel.string = 'AVERAGE'
      })
    }

    if (series?.memory.length > 0) {
      series.memory.forEach((item) => {
        newKpiData.memory.bigLabel.value = Number(calculateAverage(item.data).toFixed(2))
        newKpiData.memory.bigLabel.string = `${newKpiData.memory.bigLabel.value}%`
        newKpiData.memory.smallLabel.string = 'AVERAGE'
      })
    }

    if (series?.net.length > 0) {
      series.net.forEach((item) => {
        newKpiData.net.bigLabel.value = Number(calculateAverage(item.data).toFixed(2))
        newKpiData.net.bigLabel.string = `${newKpiData.net.bigLabel.value} KB`
        newKpiData.net.smallLabel.string = 'AVERAGE'
      })
    }

    setKpiData(newKpiData)

  }, [series])

  useEffect(() => {console.log(kpiData)}, [kpiData])

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[calc(100%-4rem)] w-full p-4">
          <ReusableSpinner size="large" />
        </div>
      ) : (
        <div className="flex flex-col h-[calc(100%-4rem)] w-full p-4">
          <div id="filters" className="flex gap-4 p-4 border-b">
            <ReusableSelect
              options={[
                {
                  label: "Last hour",
                  value: "hour",
                },
                {
                  label: "Last day",
                  value: "day",
                },
                {
                  label: "Last week",
                  value: "week",
                },
                {
                  label: "Last month",
                  value: "month",
                },
                {
                  label: "Last year",
                  value: "year",
                },
              ]}
              required={false}
              labelText="Timeframe"
              selectedValue={selectedTimeframe}
              onSelectChange={(e) => {
                handleTimeFrameChange(e.target.value);
              }}
              id="timeframe-select"
            />
            <ReusableSelect
              options={[
                {
                  label: "Average",
                  value: "AVERAGE",
                },
                {
                  label: "Max",
                  value: "MAX",
                },
              ]}
              required={false}
              labelText="Calculation"
              selectedValue={selectedCalculation}
              onSelectChange={(e) => {
                handleCalculationChange(e.target.value);
              }}
              id="timeframe-select"
            />
            {nodeOpts.length > 0 && (
              <ReusableCheckboxDropdown
                options={nodeOpts}
                label="Nodes"
                type="default"
                onSelect={handleNodeChange}
                id="node-select"
              />
            )}
            {vmOpts.length > 0 && (
              <ReusableCheckboxDropdown
                options={vmOpts}
                label="VM's"
                type="default"
                onSelect={handleVMChange}
                id="vm-select"
              />
            )}
          </div>
          <div className="flex flex-col w-full h-full gap-2 px-4 py-2">
            <div className="flex flex-col h-full w-full rounded-2xl bg-purple-50">
              <div className="flex flex-col h-full w-full items-center justify-center p-2">
                <p className="text-xl">CPU</p>
                <div className="flex w-full h-full">
                  <div className="flex w-1/5">
                    <KpiWidget
                      bigLabel={kpiData.cpu.bigLabel}
                      smallLabel={kpiData.cpu.smallLabel}
                      threshold={80}
                      belowThresholdColor={"#097969"}
                      aboveThresholdColor={"#D22B2B"}
                    />
                  </div>
                  <div className="flex w-4/5 h-full">
                    <AreaWidget
                      series={series?.cpu}
                      title={"CPU"}
                      titleEnabled={false}
                      format={"%"}
                      palette={10}
                      yAxisMin={0}
                      loading={dataLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full w-full rounded-2xl bg-purple-50">
              <div className="flex flex-col h-full w-full items-center justify-center p-2">
                <p className="text-xl">MEMORY</p>
                <div className="flex w-full h-full">
                  <div className="flex w-1/5">
                    <KpiWidget
                      bigLabel={kpiData.memory.bigLabel}
                      smallLabel={kpiData.memory.smallLabel}
                      threshold={70}
                      belowThresholdColor={"#097969"}
                      aboveThresholdColor={"#D22B2B"}
                    />
                  </div>
                  <div className="flex w-4/5 h-full">
                    <AreaWidget
                      series={series?.memory}
                      title={"MEMORY"}
                      titleEnabled={false}
                      format={"%"}
                      palette={10}
                      yAxisMin={0}
                      loading={dataLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full w-full rounded-2xl bg-purple-50">
              <div className="flex flex-col h-full w-full items-center justify-center p-2">
                <p className="text-xl">NET</p>
                <div className="flex w-full h-full">
                  <div className="flex w-1/5 h-full">
                    <KpiWidget
                      bigLabel={kpiData.net.bigLabel}
                      smallLabel={kpiData.net.smallLabel}
                      belowThresholdColor={"#097969"}
                      aboveThresholdColor={"#D22B2B"}
                    />
                  </div>
                  <div className="flex w-4/5 h-full">
                    <AreaWidget
                      series={series?.net}
                      title={"NET"}
                      titleEnabled={false}
                      format={" KB"}
                      palette={10}
                      yAxisMin={0}
                      loading={dataLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Statistics;
