import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import ReusableButton from '../buttons/ReusableButton'

export default function ReusableModal({
  openButtonLabel = 'Open Modal',
  title = 'Default Title',
  children,
  allowCloseOnOverlay = true,
  allowCloseOnButton = true,
  renderButton = true,
  externalOpen = false,
  onClose,
}) {
  const [isOpen, setIsOpen] = useState(externalOpen)

  function closeModal() {
    setIsOpen(false)
    if (onClose) {
      onClose(false) // informs parent component
    }
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    setIsOpen(externalOpen)
  }, [externalOpen])

  return (
    <>
      {renderButton && (
        <ReusableButton label={openButtonLabel} onClick={openModal} />
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={allowCloseOnOverlay ? closeModal : () => {}}
        >
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* Content */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden  bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-bold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    {allowCloseOnButton && (
                      <button onClick={closeModal}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="mt-2">
                    {typeof children === 'function'
                      ? children({ closeModal })
                      : children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
