import React, { useState } from "react";

function KpiWidget({
  bigLabel,
  smallLabel,
  threshold,
  aboveThresholdColor,
  belowThresholdColor,
}) {
  const thresholdColor = () => {
    if (!bigLabel.value) {
      // Return no color
    } else {
      if (!threshold || bigLabel.value < threshold) {
        return belowThresholdColor;
      } else {
        return aboveThresholdColor;
      }
    }
    
  };

  return (
    <div className="flex flex-col h-full w-full p-4 justify-center items-center whitespace-nowrap overflow-hidden">
      <div
        className="text-5xl font-bold"
        style={{
          color: thresholdColor(),
        }}
      >
        {bigLabel?.string ? bigLabel.string : 'No data'}
      </div>
      {smallLabel?.string && <div className="text-1xl font-semibold">{smallLabel.string}</div>}
    </div>
  );
}

export default KpiWidget;
