import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import ReusableSpinner from "../../ui/loading/ReusableSpinner";

function AreaWidget({ series, title, titleEnabled, format, palette, yAxisMin, yAxisMax, loading }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (series.length > 0) {
      const newData = [...series]
      chartRef.current.chart.updateSeries(newData, false)
    }
  }, [series])

  const options = {
    chart: {
      type: "area",
      stacked: false,
      zoom: {
        enabled: true,
      },
      redrawOnParentResize: false,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -20,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 200,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 450,
        },
      },
    },
    title: {
      text: titleEnabled ? title : "",
      align: "center",
      offsetY: 0,
      style: {
        fontSize: "16px",
        fontWeight: "semi-bold",
        fontFamily: "Helvetica, Arial, sans-serif",
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: 8,
        fontFamily: "Helvetica, Arial",
        fontWeight: "bold",
        colors: undefined,
      },
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: true,
      borderColor: "#cbd5e1",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      labels: {
        useSeriesColors: true,
      },
      position: "right",
      horizontalAlign: "center",
      floating: false,
      fontSize: 12,
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
    },
    xaxis: {
      type: "date",
      labels: {
        show: true,
        rotate: -10,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: "#555555",
          fontSize: 10,
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        format: undefined,
        formatter: undefined,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
      axisBorder: {
        show: true,
        color: "#cbd5e1",
        height: 0.5,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#cbd5e1",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      show: true,
      forceNiceScale: true,
      min: yAxisMin,
      max: yAxisMax,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: "#555555",
          fontSize: 10,
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: function (val) {
          if (isNaN(val) || val === undefined) {
            return null;
          } else {
            if (format) {
              return `${val.toFixed(2)}${format}`; //@tagg3n - We should be able to set decimals on all charts, you need to revisit appearance in preview.
            } else {
              return;
            }
          }
        },
      },
    },
    theme: {
      mode: "light",
      palette: `palette${palette || 1}`,
    },
    series: series,
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col h-full w-full p-2 items-center justify-center">
          <ReusableSpinner size="medium" />
        </div>
      ) : (
        <div className="flex flex-col h-full w-full p-2">
          <Chart
            options={options}
            series={options.series}
            type={"area"}
            height="100%"
            width="100%"
            ref={chartRef}
          />
        </div>
      )}
    </>
  );
}

export default AreaWidget;
