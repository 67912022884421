import React from 'react'

const ReusableButton = ({ type, label, loading, disabled, onClick, size }) => {
  let sizeStyles = ''
  let buttonStyles = ''
  let hoverStyles = ''

  switch (type) {
    case 'primary':
      buttonStyles = 'bg-indigo-500 text-white'
      hoverStyles = 'hover:bg-indigo-600 focus:bg-indigo-700'
      break
    case 'secondary':
      buttonStyles = 'bg-indigo-50 text-indigo-500'
      hoverStyles =
        'hover:bg-indigo-100 hover:text-indigo-600 focus:bg-indigo-200 focus:text-indigo-700'
      break
    case 'outline':
      buttonStyles = 'border border-indigo-500 text-indigo-500'
      hoverStyles =
        'hover:border-indigo-600 hover:text-indigo-600 focus:border-indigo-700 focus:text-indigo-700'
      break
    default:
      buttonStyles = 'bg-indigo-500 text-white'
      hoverStyles = 'hover:bg-indigo-600 focus:bg-indigo-700'
      break
  }

  switch (size) {
    case 'small':
      sizeStyles = 'h-8 px-4 text-xs'
      break
    default:
      sizeStyles = 'h-10 px-5 text-sm'
      break
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`inline-flex items-center justify-center gap-2 ${sizeStyles} font-medium tracking-wide transition duration-300 rounded focus-visible:outline-none whitespace-nowrap ${buttonStyles} ${
        !disabled ? hoverStyles : ''
      } ${
        disabled
          ? 'cursor-not-allowed border-indigo-300 bg-indigo-100 text-indigo-400 shadow-none'
          : ''
      }`}
    >
      <span>{loading ? 'Loading...' : label}</span>
      {loading && (
        <span className="relative">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-live="polite"
            aria-busy="true"
            aria-labelledby="title-01b desc-01b"
            className="w-4 h-4 animate animate-spin"
          >
            <title id="title-01b">Icon title</title>
            <desc id="desc-01b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-indigo-500"
              strokeWidth="4"
            />
          </svg>
        </span>
      )}
    </button>
  )
}

export default ReusableButton
