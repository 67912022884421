import React, { useState } from 'react'

export default function ReusableRadioBtnGroup({
  options,
  groupName,
  onValueChange: externalOnValueChange,
  legendText,
  layout = 'horizontal', // Default layout
}) {
  const [selectedOption, setSelectedOption] = useState(null)

  const internalOnValueChange = (e) => {
    const value = e.target.value
    setSelectedOption(value)
    if (externalOnValueChange) {
      externalOnValueChange(value)
    }
  }

  const fieldsetLayout =
    layout === 'vertical' ? 'flex flex-col gap-2' : 'flex gap-8'

  return (
    <>
      <fieldset className={fieldsetLayout}>
        <legend className="mb-2 text-slate-500">{legendText || ''}</legend>
        {options.map((option, index) => (
          <div key={index} className="relative flex items-center ">
            <input
              className="w-4 h-4 transition-colors bg-white border-2 rounded-full appearance-none cursor-pointer peer border-slate-500 checked:border-indigo-500 checked:bg-indigo-200 checked:hover:border-indigo-600 checked:hover:bg-indigo-300 focus:outline-none checked:focus:border-indigo-700 checked:focus:bg-indigo-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
              type="radio"
              value={option.value}
              id={option.id}
              name={groupName}
              onChange={internalOnValueChange}
            />
            <label
              className="pl-2 cursor-pointer text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
              htmlFor={option.id}
            >
              {option.label}
            </label>
            <svg
              className="absolute left-0 w-4 h-4 transition-all duration-300 scale-50 opacity-0 pointer-events-none fill-indigo-500 peer-checked:scale-100 peer-checked:opacity-100 peer-hover:fill-indigo-600 peer-focus:fill-indigo-700 peer-disabled:cursor-not-allowed"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby={`title-${index} description-${index}`}
              role="graphics-symbol"
            >
              <title id={`title-${index}`}>Circle Shape</title>
              <desc id={`description-${index}`}>
                Circle shape to indicate whether the radio input is checked or
                not.
              </desc>
              <circle cx="8" cy="8" r="4" />
            </svg>
          </div>
        ))}
      </fieldset>
    </>
  )
}
