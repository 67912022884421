import React from 'react'

export default function ReusableConnectionBadge({ status }) {
  return (
    <div className="flex items-center justify-center gap-2">
      <span
        className={`h-2 w-2 rounded-full ${
          status === 'Yes' ? 'bg-green-500' : 'bg-gray-600'
        }`}
      ></span>
      <span
        className={`text-xs font-light tracking-wide ${
          status === 'Yes' ? 'text-green-500' : 'text-gray-600'
        }`}
      >
        {status === 'Yes' ? 'Connected' : 'Disconnected'}
      </span>
    </div>
  )
}
