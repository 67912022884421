import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { appService } from '../../services/app.service'
import { Dialog, Transition } from '@headlessui/react'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from 'react-table'

import _ from 'lodash'
import { DateTime } from 'luxon'
import ReusableDropdown from '../../components/ui/dropdowns/ReusableDropdown'
import ReusableModal from '../../components/ui/modals/ReusableModal'
import ReusableConnectionBadge from '../../components/ui/badges/ReusableConnectionBadge'

function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter)
  const onChange = (value) => {
    setFilter(value || undefined)
  }

  return (
    <div className="relative text-gray-600 focus-within:text-gray-400">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-4 h-4"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <span className="flex items-center">
        <input
          name="q"
          type="search"
          className="py-1 text-sm input-sm text-slate-900 dark:text-slate-400 bg-white dark:bg-slate-900 border border-slate-300 dark:border-slate-700 w-72 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
          placeholder="Search users.."
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </span>
    </div>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Checkbox({ indeterminate, ...rest }) {
  const ref = React.useRef()

  useEffect(() => {
    ref.current.indeterminate = indeterminate
  }, [indeterminate])

  return (
    <div className="flex">
      <input
        type="checkbox"
        ref={ref}
        {...rest}
        className="checkbox checkbox-sm checkbox-accent"
      />
    </div>
  )
}

function limitTextLength(text, maxLength = 25) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
}

function CustomerUsers({ customer }) {
  const [users, setUsers] = useState([])
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [user, setUser] = useState(null)

  const getUsers = async () => {

    const connectedClients = await appService.getConnectedClients()
    const result = await appService.getUsers(customer?.customerId)
    const userConnectedMap = {}

    connectedClients.forEach((entry) => {
      const userId = entry.userId
      userConnectedMap[userId] = 'Yes'
    })

    const usersWithConnectedStatus = result.map((user) => ({
      ...user,
      userConnected: userConnectedMap[user.userId] || 'No',
    }))

    setUsers(usersWithConnectedStatus)
  }

  useEffect(() => {
    getUsers()
  }, [customer])

  const handleOpenModal = () => {
    setUserModalOpen(true)
  }

  const handleCloseModal = () => {
    setUserModalOpen(false)
  }

  const columns = useMemo(
    () => [
      // {
      //   id: "selection",
      //   width: "w-8 sm:w-auto",
      //   Header: ({ getToggleAllRowsSelectedProps }) => (
      //     <Checkbox {...getToggleAllRowsSelectedProps()} />
      //   ),
      //   Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
      //   canFilter: false,
      // },
      {
        Header: 'User ID',
        accessor: 'userId',
        width: 'w-auto',
        canFilter: true,
      },
      {
        Header: 'Name',
        accessor: 'userName',
        width: 'w-auto',
        canFilter: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 'w-auto',
        canFilter: true,
        Cell: ({ value }) => <div>{limitTextLength(value)}</div>,
      },
      {
        Header: 'Active',
        accessor: 'active',
        width: 'w-auto',
        canFilter: false,
        Cell: ({ value }) => <div>{value === 1 ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Google user',
        accessor: 'googleUser',
        width: 'w-auto',
        canFilter: false,
        Cell: ({ value }) => <div>{value === 1 ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'User Connected',
        accessor: 'userConnected',
        width: 'w-auto',
        canFilter: true,
        Cell: ({ value }) => (
          <div className="flex justify-start">
            <ReusableConnectionBadge status={value} />
          </div>
        ),
      },
      {
        Header: 'Last login',
        accessor: 'lastLogin',
        width: 'w-auto',
        canFilter: true,
        Cell: ({ value }) => (
          <div>
            {value
              ? DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss')
              : 'Never'}
          </div>
        ),
      },
      {
        Header: '',
        canFilter: false,
        accessor: 'options',
        width: 'w-12 sm:w-auto mt-1',
        Cell: ({ row }) => (
          <ReusableDropdown
            type="kebab"
            options={[
              {
                label: 'Open user',
                onClick: () => {
                  setUser(row.original)
                  handleOpenModal()
                },
                icon: () => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                ),
              },
            ]}
          />
        ),
      },
    ],
    [],
  )

  const data = useMemo(() => users || [], [users])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    },
  )

  return (
    <>
      <ReusableModal
        title="User information"
        renderButton={false}
        externalOpen={userModalOpen}
        onClose={handleCloseModal}
        allowCloseOnButton={true}
        allowCloseOnOverlay={true}
      >
        {({ closeModal }) => (
          <div className="flex text-sm">
            <div className="text-sm pb-8 w-1/2">
              <br />
              <strong>User ID:</strong> {user?.userId}
              <br />
              <br />
              <strong>Email:</strong> {user?.email}
              <br />
              <br />
              <strong>Google user:</strong>{' '}
              {user?.googleUser === 1 ? 'Yes' : 'No'}
              <br />
              <br />
              <strong>Team ID:</strong> {user?.teamId}
              <br />
              <br />
              <strong>GenesysCloud ID:</strong>{' '}
              {user?.genesysCloudId ? user?.genesysCloudId : 'N/A'}
              <br />
              <br />
              <strong>Zendesk ID:</strong>{' '}
              {user?.zendeskId ? user?.zendeskId : 'N/A'}
              <br />
              <br />
              <strong>Salesforce ID:</strong>{' '}
              {user?.salesforceId ? user?.salesforceId : 'N/A'}
              <br />
              <br />
              <strong>Token expiration:</strong>{' '}
              {DateTime.fromISO(user?.userTokenExpiration).toFormat(
                'yyyy-MM-dd HH:mm:ss',
              )}
              <br />
              <br />
              <strong>Created:</strong>{' '}
              {DateTime.fromISO(user?.created).toFormat('yyyy-MM-dd HH:mm:ss')}
            </div>
            <div className="text-sm pb-8 w-1/2">
              <br />
              <strong>User name:</strong> {user?.userName}
              <br />
              <br />
              <strong>Active:</strong> {user?.active === 1 ? 'Yes' : 'No'}
              <br />
              <br />
              <strong>Timezone:</strong>{' '}
              {user?.timeZone ? user?.timeZone : 'Not set'}
              <br />
              <br />
              <strong>Team name:</strong> {user?.teamName}
              <br />
              <br />
              <strong>GenesysCloud active:</strong>{' '}
              {user?.genesysCloudActive === 1 ? 'Yes' : 'No'}
              <br />
              <br />
              <strong>Zendesk active:</strong>{' '}
              {user?.zendeskActive === 1 ? 'Yes' : 'No'}
              <br />
              <br />
              <strong>Salesforce active:</strong>{' '}
              {user?.salesforceActive === 1 ? 'Yes' : 'No'}
              <br />
              <br />
              <strong>Last login:</strong>{' '}
              {user?.lastLogin
                ? DateTime.fromISO(user?.lastLogin).toFormat(
                    'yyyy-MM-dd HH:mm:ss',
                  )
                : 'Never'}
              <br />
              <br />
              <strong>Updated:</strong>{' '}
              {DateTime.fromISO(user?.updated).toFormat('yyyy-MM-dd HH:mm:ss')}
            </div>
          </div>
        )}
      </ReusableModal>
      <div className="flex flex-col h-full w-full px-4">
        <div className="flex justify-between w-full gap-4 pb-4 pt-4">
          <p className="font-semibold">Users</p>
          <GlobalFilter
            filter={state.globalFilter}
            setFilter={setGlobalFilter}
          />
        </div>
        <section className="h-full w-full overflow-y-auto overflow-x-auto">
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-neutral "
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className=" py-3 text-left text-xs uppercase tracking-wider sticky top-0 z-10 bg-white"
                    >
                      <span
                        className={`${column.width} flex items-center gap-4`}
                      >
                        {column.render('Header')}
                        {column.id !== 'selection' &&
                          column.id !== 'options' &&
                          column.id !== 'Integrations' && (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </span>
                          )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="text-xs font-light divide-y"
            >
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      </div>
    </>
  )
}

export default CustomerUsers
