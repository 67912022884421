import React, { useEffect, useMemo, useState } from 'react'
import { appService } from '../../services/app.service'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from 'react-table'

import { DateTime } from 'luxon'
import { useNavigate } from 'react-router'

function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter)
  const onChange = (value) => {
    setFilter(value || undefined)
  }

  return (
    <div className="relative text-gray-600 focus-within:text-gray-400">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-4 h-4"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <span className="flex items-center">
        <input
          name="q"
          type="search"
          className="py-2 text-sm input-sm text-slate-900 dark:text-slate-400 bg-white dark:bg-slate-900 border border-slate-300 dark:border-slate-700 w-72 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
          placeholder="Search customers.."
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </span>
    </div>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Customers() {
  const [customers, setCustomers] = useState([])

  const navigate = useNavigate()

  const getCustomers = async () => {
    const result = await appService.getCustomers()
    const connectedClients = await appService.getConnectedClients()

    const customerCounts = connectedClients.reduce((counts, entry) => {
      const customerId = entry.customerId
      counts[customerId] = (counts[customerId] || 0) + 1
      return counts
    }, {})

    const customersWithCounts = result.map((customer) => ({
      ...customer,
      connectedClientCount: customerCounts[customer.customerId] || 0,
    }))

    setCustomers(customersWithCounts)
  }

  useEffect(() => {
    getCustomers()
  }, [])

  function goToCustomer(customer) {
    navigate(`/customers/${customer.customerId}`)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Customer ID',
        accessor: 'customerId',
        width: 'w-auto',
        canFilter: true,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        width: 'w-auto',
        canFilter: true,
      },
      {
        Header: 'License',
        accessor: 'licenseName',
        width: 'w-auto',
        canFilter: true,
      },
      {
        Header: 'Active',
        accessor: 'active',
        width: 'w-auto',
        canFilter: true,
        Cell: ({ value }) => <div>{value === 1 ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Connected Clients',
        accessor: 'connectedClientCount',
        width: 'w-auto',
        canFilter: true,
      },
      {
        Header: 'Created',
        accessor: 'created',
        width: 'w-auto',
        canFilter: true,
        Cell: ({ value }) => (
          <div>{DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
        ),
      },
      {
        Header: '',
        canFilter: false,
        accessor: 'options',
        width: 'w-12 sm:w-auto',
        Cell: ({ row }) => (
          <button
            onClick={() => {
              goToCustomer(row.original)
            }}
            className="flex justify-between"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 stroke-indigo-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        ),
      },
    ],
    [],
  )

  const data = useMemo(() => customers || [], [customers])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    },
  )

  return (
    <>
      <div className="h-[calc(100%-4rem)] w-full p-4">
        <div className="flex justify-between w-full my-4 pt-2">
          <GlobalFilter
            filter={state.globalFilter}
            setFilter={setGlobalFilter}
          />
        </div>
        <section className=" lg:h-[calc(100vh-18rem)] w-full overflow-y-auto overflow-x-auto">
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-neutral "
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className=" py-3 text-left text-sm uppercase tracking-wider sticky top-0 bg-base-100 z-10"
                    >
                      <span
                        className={`${column.width} flex items-center gap-4`}
                      >
                        {column.render('Header')}
                        {column.id !== 'selection' &&
                          column.id !== 'options' &&
                          column.id !== 'Integrations' && (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </span>
                          )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="divide-y">
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      </div>
    </>
  )
}

export default Customers
