import { useContext } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import AuthContext from '../contexts/AuthContext'

const useLogout = () => {
  const { setIsLoggedIn } = useContext(AuthContext)
  const auth = getAuth()

  const logout = async () => {
    try {
      await signOut(auth)
      setIsLoggedIn(false)
    } catch (error) {
      console.error('Logout Error:', error)
    }
  }

  return logout
}

export default useLogout
