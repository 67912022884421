import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { appService } from '../../services/app.service'
import { Dialog, Transition } from '@headlessui/react'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from 'react-table'

import _ from 'lodash'
import { DateTime } from 'luxon'
import ReusableDropdown from '../../components/ui/dropdowns/ReusableDropdown'
import ReusableModal from '../../components/ui/modals/ReusableModal'
import ReusableToggle from '../../components/ui/toggles/ReusableToggle'
import ReusableButton from '../../components/ui/buttons/ReusableButton'

function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter)
  const onChange = (value) => {
    setFilter(value || undefined)
  }

  return (
    <div className="relative text-gray-600 focus-within:text-gray-400">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-4 h-4"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <span className="flex items-center">
        <input
          name="q"
          type="search"
          className="py-2 text-sm input-sm text-slate-900 dark:text-slate-400 bg-white dark:bg-slate-900 border border-slate-300 dark:border-slate-700 w-72 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
          placeholder="Search logs.."
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </span>
    </div>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Checkbox({ indeterminate, ...rest }) {
  const ref = React.useRef()

  useEffect(() => {
    ref.current.indeterminate = indeterminate
  }, [indeterminate])

  return (
    <div className="flex">
      <input
        type="checkbox"
        ref={ref}
        {...rest}
        className="checkbox checkbox-sm checkbox-accent"
      />
    </div>
  )
}

function ServerLogs() {
  const [serverLogs, setServerLogs] = useState([])
  const [filteredServerLogs, setFilteredServerLogs] = useState([])
  const [logModalOpen, setLogModalOpen] = useState(false)
  const [log, setLog] = useState(null)
  const [filterResolved, setFilterResolved] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const getServerLogs = async () => {
    const result = await appService.getServerLogs()
    setServerLogs(result)
  }

  useEffect(() => {
    getServerLogs()
  }, [])

  useEffect(() => {
    if (serverLogs.length > 0) {
      const logs = [...serverLogs]
      if (!filterResolved) {
        setFilteredServerLogs(logs.filter((log) => log.resolved === 0))
      } else {
        setFilteredServerLogs(logs)
      }
    }
  }, [serverLogs])

  const handleFilterResolved = (filter) => {
    setFilterResolved(filter)
    const logs = [...serverLogs]
    if (!filter) {
      setFilteredServerLogs(logs.filter((log) => log.resolved === 0))
    } else {
      setFilteredServerLogs(logs)
    }
  }

  const columns = useMemo(
    () => [
      // {
      //   id: "selection",
      //   width: "w-8 sm:w-auto",
      //   Header: ({ getToggleAllRowsSelectedProps }) => (
      //     <Checkbox {...getToggleAllRowsSelectedProps()} />
      //   ),
      //   Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
      //   canFilter: false,
      // },
      {
        Header: 'Log ID',
        accessor: 'logId',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Environment',
        accessor: 'environment',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Function',
        accessor: 'jsFunction',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Customer ID',
        accessor: 'customerId',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'User ID',
        accessor: 'userId',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Type',
        accessor: 'logType',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Message',
        accessor: 'logMessage',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Occurances',
        accessor: 'logCount',
        width: 'w-64 sm:w-auto',
        canFilter: false,
      },
      {
        Header: 'Resolved',
        accessor: 'resolved',
        width: 'w-64 sm:w-auto',
        canFilter: true,
        Cell: ({ value }) => <div>{value === 1 ? 'Yes' : 'No'}</div>,
      },
      {
        Header: '',
        canFilter: false,
        accessor: 'options',
        width: 'w-12 sm:w-auto',
        Cell: ({ row }) => (
          <ReusableDropdown
            type="kebab"
            options={[
              {
                label: 'Open log',
                onClick: () => {
                  // setLogModalOpen(true)
                  setLog(row.original)
                  // setModalOpen(true)
                  handleOpenModal()
                },
                icon: () => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                ),
              },
              {
                label: 'Resolve log',
                onClick: () => {
                  resolveLog(row.original)
                },
                icon: () => (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ),
              },
            ]}
          />
        ),
      },
    ],
    [],
  )

  const data = useMemo(() => filteredServerLogs || [], [filteredServerLogs])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    },
  )

  const resolveLog = async (log) => {
    await appService.resolveLog(log.logId)
    await getServerLogs()
    setLogModalOpen(false)
  }

  return (
    <>
      <ReusableModal
        title="Log Information"
        renderButton={false}
        externalOpen={modalOpen}
        onClose={handleCloseModal}
        allowCloseOnButton={true}
        allowCloseOnOverlay={true}
      >
        {({ closeModal }) => (
          <div className="text-sm">
            <br />
            <strong>Message:</strong> {log?.logMessage}
            <br />
            <br />
            <strong>Stack:</strong> {log?.logStack}
            <br />
            <br />
            <strong>SQL:</strong> {log?.logSql === 'null' ? '' : log?.logSql}
            <br />
            <br />
            <strong>Created:</strong>{' '}
            {DateTime.fromISO(log?.created).toFormat('yyyy-MM-dd HH:mm:ss')}
            <br />
            <strong>Updated:</strong>{' '}
            {DateTime.fromISO(log?.updated).toFormat('yyyy-MM-dd HH:mm:ss')}
            <div className="flex gap-2 mt-6">
              <ReusableButton
                type="primary"
                label="Resolve"
                onClick={() => resolveLog(log)}
              />
              <ReusableButton
                type="outline"
                label="Close"
                onClick={closeModal}
              />
            </div>
          </div>
        )}
      </ReusableModal>

      <div className="h-[calc(100%-4rem)] w-full p-4">
        <div className="flex justify-between w-full my-4 pt-2">
          <GlobalFilter
            filter={state.globalFilter}
            setFilter={setGlobalFilter}
          />
          <div className="flex items-center gap-2 uppercase text-xs">
            {filterResolved ? 'All' : 'Unresolved'}
            <ReusableToggle
              id="log-filter"
              label=""
              isChecked={filterResolved}
              onChange={(e) => handleFilterResolved(e.target.checked)}
            />
          </div>
        </div>
        <section className=" lg:h-[calc(100vh-18rem)] w-full overflow-y-auto overflow-x-auto text-sm">
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-neutral "
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className=" py-3 text-left text-xs uppercase tracking-wider sticky top-0 bg-base-100 z-10"
                    >
                      <span
                        className={`${column.width} flex items-center gap-1`}
                      >
                        {column.render('Header')}
                        {column.id !== 'selection' &&
                          column.id !== 'options' &&
                          column.id !== 'Integrations' && (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </span>
                          )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="">
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      </div>
    </>
  )
}

export default ServerLogs
