import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { RecaptchaVerifier } from '../../firebase/config'
import logo from './assets/logo_blue.svg'

function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [resolver, setResolver] = useState(null)
  const [verificationId, setVerificationId] = useState('')

  const navigate = useNavigate()

  const auth = getAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await signInWithEmailAndPassword(auth, email, password)

      navigate('/')
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        setShowCodeInput(true)
        const multiFactorResolver = getMultiFactorResolver(auth, error)
        setResolver(multiFactorResolver)
      } else if (error.code === 'auth/wrong-password') {
        console.error('Wrong password')
      }
    }
  }

  const handleVerifyCode = async (e) => {
    e.preventDefault()
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    try {
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion)

      navigate('/')
    } catch (error) {
      console.error('Failed to resolve sign-in:', error)
    }
  }

  const handleSendVerificationCode = async () => {
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response) {
          return response
        },
      },
    )
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    }
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    try {
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      )
      setVerificationId(newVerificationId)
    } catch (error) {
      console.error('Failed to send verification code:', error)
    }
  }

  useEffect(() => {
    if (resolver && showCodeInput) {
      handleSendVerificationCode()
    }
  }, [resolver, showCodeInput])

  return (
    <div className="min-h-screen bg-slate-200 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-violet-500 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="w-96 max-w-md mx-auto">
            <div className="flex items-center gap-4">
              <img className="h-10 w-10" src={logo} alt="logotype" />
              <h1 className="text-2xl font-semibold">Login</h1>
            </div>
            <div className="divide-y divide-gray-200">
              <form
                onSubmit={showCodeInput ? handleVerifyCode : handleSubmit}
                className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7"
              >
                <div className="relative">
                  <input
                    autoComplete="off"
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                    placeholder="Email address"
                  />
                  <label
                    htmlFor="email"
                    className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    Email Address
                  </label>
                </div>
                <div className="relative">
                  <input
                    autoComplete="off"
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                    placeholder="Password"
                  />

                  <label
                    htmlFor="password"
                    className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    Password
                  </label>
                </div>

                <div className="relative">
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 focus:bg-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-300 disabled:shadow-none"
                  >
                    <span> {showCodeInput ? 'Verify Code' : 'Log in'}</span>
                  </button>
                </div>
                {showCodeInput && (
                  <div className="relative">
                    <input
                      autoComplete="off"
                      id="verificationCode"
                      name="verificationCode"
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-rose-600"
                      placeholder="Verification Code"
                    />
                    <label
                      htmlFor="verificationCode"
                      className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                    >
                      Verification Code
                    </label>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
