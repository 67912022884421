import React, { useEffect, useMemo, useState } from 'react'
import { appService } from '../../services/app.service'
import { useLocation, useNavigate } from 'react-router'
import {
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import GaugeWidget from '../../components/widgets/gauge/GaugeWidget'
import ProgressBar from '../../components/ui/loading/ProgressBar'

function statusIcon(status) {
  switch (status) {
    case 'online':
    case 'running':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 stroke-green-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )
    case 'offline':
    case 'stopped':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 stroke-red-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 stroke-orange-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
      )
  }
}

function Timer({ inputTimeInSeconds }) {
  const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(
    inputTimeInSeconds,
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTimeInSeconds((prevElapsedTime) => prevElapsedTime + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const days = Math.floor(elapsedTimeInSeconds / 86400)
  const remainingTimeAfterDays = elapsedTimeInSeconds % 86400
  const hours = Math.floor(remainingTimeAfterDays / 3600)
  const remainingTimeAfterHours = remainingTimeAfterDays % 3600
  const minutes = Math.floor(remainingTimeAfterHours / 60)
  const seconds = remainingTimeAfterHours % 60

  return (
    <div className="flex w-full items-center">
      <div className="flex w-full justify-center gap-2">
        <div className="flex flex-col justify-center items-center">
          <span className="text-xl">{days < 10 ? '0' + days : days}</span>
          <span className="text-xs">days</span>
        </div>
        <span className="-translate-y-[0.1rem] text-xl">:</span>
        <div className="flex flex-col justify-center items-center">
          <span className="text-xl">{hours < 10 ? '0' + hours : hours}</span>
          <span className="text-xs">hours</span>
        </div>
        <span className="-translate-y-[0.1rem] text-xl">:</span>
        <div className="flex flex-col justify-center items-center">
          <span className="text-xl">
            {minutes < 10 ? '0' + minutes : minutes}
          </span>
          <span className="text-xs">minutes</span>
        </div>
        <span className="-translate-y-[0.1rem] text-xl">:</span>
        <div className="flex flex-col justify-center items-center">
          <span className="text-xl">
            {seconds < 10 ? '0' + seconds : seconds}
          </span>
          <span className="text-xs">seconds</span>
        </div>
      </div>
    </div>
  )
}

function TableTimer({ inputTimeInSeconds }) {
  const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(
    inputTimeInSeconds,
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTimeInSeconds((prevElapsedTime) => prevElapsedTime + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const days = Math.floor(elapsedTimeInSeconds / 86400)
  const remainingTimeAfterDays = elapsedTimeInSeconds % 86400
  const hours = Math.floor(remainingTimeAfterDays / 3600)
  const remainingTimeAfterHours = remainingTimeAfterDays % 3600
  const minutes = Math.floor(remainingTimeAfterHours / 60)
  const seconds = remainingTimeAfterHours % 60

  return (
    <div className="flex items-start flex-col">
      <div className="flex items-center">
        <div className="flex flex-col justify-center items-center pr-1 w-16">
          <span className="">{days < 10 ? '0' + days : days} days</span>
        </div>
        <div className="flex flex-col justify-center items-center w-6">
          <span className="">{hours < 10 ? '0' + hours : hours}:</span>
        </div>
        <div className="flex flex-col justify-center items-center w-6">
          <span className="">{minutes < 10 ? '0' + minutes : minutes}:</span>
        </div>
        <div className="flex flex-col justify-center items-center w-6">
          <span className="">{seconds < 10 ? '0' + seconds : seconds}</span>
        </div>
      </div>
    </div>
  )
}

function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter)
  const onChange = (value) => {
    setFilter(value || undefined)
  }

  return (
    <div className="relative text-gray-600 focus-within:text-gray-400">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-4 h-4"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <span className="flex items-center">
        <input
          name="q"
          type="search"
          className="py-2 text-sm input-sm text-slate-900 dark:text-slate-400 bg-white dark:bg-slate-900 border border-slate-300 dark:border-slate-700 w-72 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
          placeholder="Search nodes.."
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </span>
    </div>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function NodePage() {
  const [node, setNode] = useState(null)
  const [loading, setLoading] = useState(true)
  const [vms, setVms] = useState([])

  const location = useLocation()
  const navigate = useNavigate()
  const parts = location.pathname.split('/')
  const nodeName = parts[parts.length - 1]

  const getNode = async () => {
    const nodes = await appService.getNodes()
    const node = nodes.find((x) => x.node === nodeName)
    // console.log(node)
    setNode(node)

    const vms = await appService.getVMs(nodeName)
    // console.log(vms)
    setVms(
      vms.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      }),
    )
    setLoading(false)
  }

  useEffect(() => {
    getNode()

    const intervalId = setInterval(() => {
      getNode()
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 'w-64 sm:w-auto',
        canFilter: true,
        Cell: ({ value }) => (
          <div className="h-16 flex items-center">{value.toUpperCase()}</div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value }) => (
          <div className="flex gap-2 items-center">
            {value.charAt(0).toUpperCase() + value.slice(1)}
            {statusIcon(value)}
          </div>
        ),
      },
      {
        Header: 'Uptime',
        accessor: 'uptime',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value }) => <TableTimer inputTimeInSeconds={value} />,
      },
      {
        Header: 'CPU',
        accessor: 'cpu',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value, row }) => {
          const percentage = value
          const string = `${(value * 100).toFixed(2)}% of ${
            row.original.cpus
          } CPU's`

          return (
            <div className="mr-4 max-w-72">
              <ProgressBar value={percentage} max={1}></ProgressBar>
              <span className="text-xs">{string}</span>
            </div>
          )
        },
      },
      {
        Header: 'Memory',
        accessor: 'mem',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value, row }) => {
          const percentage = value / row.original.maxmem
          const string = `${(value / 1073741824).toFixed(2)} of ${(
            row.original.maxmem / 1073741824
          ).toFixed(2)} GB`

          return (
            <div className="mr-4 max-w-72">
              <ProgressBar value={percentage} max={1}></ProgressBar>
              <span className="text-xs">{string}</span>
            </div>
          )
        },
      },
      {
        Header: 'Disk',
        accessor: 'disk',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value, row }) => {
          const percentage = value / row.original.maxdisk
          const string = `${(value / 1073741824).toFixed(2)} of ${(
            row.original.maxdisk / 1073741824
          ).toFixed(2)} GB`

          return (
            <div className="mr-4 max-w-72">
              <ProgressBar value={percentage} max={1}></ProgressBar>
              <span className="text-xs">{string}</span>
            </div>
          )
        },
      },
    ],
    [],
  )

  const data = useMemo(() => vms || [], [vms])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    },
  )

  return (
    <div className="flex flex-col p-4 bg-base-300 w-full h-[calc(100%-4rem)]">
      <button
        className="flex items-center gap-4 pb-2"
        onClick={() => {
          navigate('/nexus')
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Back to Nexus
      </button>
      {loading ? (
        <div></div>
      ) : (
        <div className="flex flex-col w-full h-full gap-4">
          <div className="flex flex-col items-center h-[45%]">
            <div id="health" className="flex">
              <div className="flex flex-col items-center justify-center h-full w-full p-2 divide-y">
                <div className="flex gap-2 text-xl justify-center items-center p-2">
                  {node?.status.toUpperCase()}
                  {statusIcon(node?.status)}
                </div>
                <div className="flex w-full justify-center p-2">
                  <Timer inputTimeInSeconds={node?.uptime} />
                </div>
              </div>
            </div>
            <div
              id="resources"
              className="flex w-full h-full justify-center gap-7"
            >
              <GaugeWidget
                value={[node?.cpu * 100]}
                dataLabel={`${(node?.cpu * 100).toFixed(2)}% of ${
                  node?.maxcpu
                } CPU's`}
                title={'CPU'}
                threshold={60}
                belowThresholdColor={{ hex: '#097969' }}
                aboveThresholdColor={{ hex: '#D22B2B' }}
              />
              <GaugeWidget
                value={[(node?.mem / node?.maxmem) * 100]}
                dataLabel={`${(node?.mem / 1073741824).toFixed(2)} of ${(
                  node?.maxmem / 1073741824
                ).toFixed(2)} GB`}
                title={'MEMORY'}
                threshold={60}
                belowThresholdColor={{ hex: '#097969' }}
                aboveThresholdColor={{ hex: '#D22B2B' }}
              />
              <GaugeWidget
                value={[(node?.disk / node?.maxdisk) * 100]}
                dataLabel={`${(node?.disk / 1073741824).toFixed(2)} of ${(
                  node?.maxdisk / 1073741824
                ).toFixed(2)} GB`}
                title={'DISK'}
                threshold={60}
                belowThresholdColor={{ hex: '#097969' }}
                aboveThresholdColor={{ hex: '#D22B2B' }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full h-[55%]">
            <div className="flex justify-between w-full p-4">
              <GlobalFilter
                filter={state.globalFilter}
                setFilter={setGlobalFilter}
              />
            </div>
            <section className="w-full h-full p-4 overflow-y-auto overflow-x-auto">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-neutral "
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          className="py-3 text-left text-sm uppercase tracking-wider sticky top-0 bg-base-100 z-10"
                        >
                          <span
                            className={`${column.width} flex items-center gap-1`}
                          >
                            {column.render('Header')}
                            {column.id !== 'selection' &&
                              column.id !== 'options' &&
                              column.id !== 'Integrations' && (
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-4 h-4"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-4 h-4"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    )
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="w-4 h-4"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </span>
                              )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {rows.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr
                        className={index % 2 === 0 ? 'bg-white' : 'bg-slate-50'}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </section>
          </div>
        </div>
      )}
    </div>
  )
}

export default NodePage
