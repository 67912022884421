import React, { useState, useEffect } from 'react'
import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
} from 'firebase/auth'
import useAuth from '../../firebase/useAuth'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const auth = getAuth()

function ActivateMFA() {
  const { user } = useAuth()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    const container =
      document.getElementById('recaptcha-container') ||
      document.createElement('div')
    container.id = 'recaptcha-container'
    document.body.appendChild(container)

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response) {
          return response
        },
      },
    )
  }, [])

  const sendPhoneVerification = async () => {
    try {
      const multiFactorSession = await multiFactor(user).getSession()
      console.log('multiFactorSession:', multiFactorSession) // Debug log

      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession,
      }

      const phoneProvider = new PhoneAuthProvider(auth)

      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier,
      )

      setVerificationId(verificationId)
    } catch (e) {
      setError(e.message)
    }
  }

  const enableMFA = async () => {
    try {
      const phoneCredential = PhoneAuthProvider.credential(verificationId, code)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
        phoneCredential,
      )
      await multiFactor(user).enroll(multiFactorAssertion, 'phone')
      console.log('MFA Enabled')
    } catch (e) {
      setError(e.message)
    }
  }

  return (
    <div className="">
      <div className="flex gap-3">
        <p className="font-semibold text-sm">
          Activate Multi factor authentication
        </p>
        {error && <p className="text-sm text-rose-400">{error}</p>}
      </div>
      <div>
        <div className="flex items-end gap-2 my-4">
          <div className="relative">
            <PhoneInput
              defaultCountry="SE"
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="peer relative h-10 w-60 rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-indigo-500 focus:outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
            />
            <label
              htmlFor="id-11"
              className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:left-10 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:left-2 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-indigo-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
            >
              Phone number
            </label>
          </div>
          <button
            onClick={sendPhoneVerification}
            className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-indigo-50 px-5 text-sm font-medium tracking-wide text-indigo-500 transition duration-300 hover:bg-indigo-100 hover:text-indigo-600 focus:bg-indigo-200 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-100 disabled:text-indigo-400 disabled:shadow-none"
          >
            <span>Send</span>
          </button>
        </div>
      </div>
      {verificationId && (
        <div>
          <div className="flex items-end gap-2 my-4">
            <div class="relative">
              <input
                id="id-01"
                type="text"
                name="id-01"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Verification code"
                class="relative w-60 h-10 px-4 text-sm placeholder-transparent transition-all border rounded outline-none focus-visible:outline-none peer border-slate-200 text-slate-500 autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-indigo-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
              />
              <label
                for="id-01"
                class="cursor-text peer-focus:cursor-default absolute left-2 -top-2 z-[1] px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-indigo-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
              >
                Verification code
              </label>
            </div>
            <button
              onClick={enableMFA}
              className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 focus:bg-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-300 disabled:shadow-none"
            >
              <span>Enable MFA</span>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ActivateMFA
