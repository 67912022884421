import axios from "axios";

const intellyServerUrl = process.env.REACT_APP_INTELLY_SERVER_URL
const intellyDevServerUrl = process.env.REACT_APP_INTELLY_DEV_SERVER_URL
const nexusUrl = process.env.REACT_APP_NEXUS_URL

const nexusTokenConfig = {
  headers: {
    Authorization: process.env.REACT_APP_NEXUS_API_TOKEN,
    'Content-Type': 'application/json;charset=UTF-8',
  }
}

const intellyServerTokenConfig = {
  headers: {
    Authorization: 'Basic cGhpbGlwQGludGVsbHkuc29sdXRpb25zOlMxeEhPdnF4N21NMjNNMHBBbnRvUWZENXROc0Jhek0yZkFhdUpTTGQ=', // NEEDS TO BE REPLACED WITH CURRENT USERS TOKEN (NEEDS TO BE SET ON GOOGLE USER)
    'Content-Type': 'application/json;charset=UTF-8',
  }
}

async function getServerLogs() {
  try {
    const result = await axios.get(`${intellyServerUrl}admin/server-logs`, intellyServerTokenConfig)
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function resolveLog(logId) {
  try {
    const result = await axios.post(
      `${intellyServerUrl}admin/server-logs/resolution?logId=${logId}&resolved=1`,
      {},
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getCustomers(customerId, environment = "production") {
  try {
    const result = await axios.get(
      `${environment === "production" ? intellyServerUrl : intellyDevServerUrl }customers/customer${customerId ? `?customerId=${customerId}` : ''}`,
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getUser(customerId, userId) {
  try {
    const result = await axios.get(
      `${intellyServerUrl}users/user?customerId=${customerId}&userId=${userId}`,
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getUsers(customerId, environment = "production") {
  try {
    const result = await axios.get(
      `${environment === "production" ? intellyServerUrl : intellyDevServerUrl}users?customerId=${customerId}`,
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getAccount(customerId) {
  try {
    const result = await axios.get(
      `${intellyServerUrl}customers/customer/myaccount?customerId=${customerId}`,
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getNodes() {
  try {
    console.log(  `${nexusUrl}nodes`)
    const result = await axios.get(
      `${nexusUrl}nodes`,
      nexusTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getVMs(node) {
  try {
    const result = await axios.get(
      `${nexusUrl}nodes/${node}/qemu`,
      nexusTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getCardInfo(customerId) {
  try {
    const result = await axios.get(
      `${intellyServerUrl}subscription/cardinfo?customerId=${customerId}`,
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getInvoices(customerId) {
  try {
    const result = await axios.get(
      `${intellyServerUrl}subscription/invoices?customerId=${customerId}`,
      intellyServerTokenConfig
    )
    return result.data
  } catch (err) {
    console.log(err)
  }
}

async function getNodeStatistics(node, timeframe, calculation) {
  try {
    const result = await axios.get(
      `${nexusUrl}nodes/${node}/rrddata?timeframe=${timeframe}&cf=${calculation}`,
      nexusTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getVMStatistics(node, vmId, timeframe, calculation) {
  try {
    const result = await axios.get(
      `${nexusUrl}nodes/${node}/qemu/${vmId}/rrddata?timeframe=${timeframe}&cf=${calculation}`,
      nexusTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

async function getConnectedClients(customerId) {
  try {
    const result = await axios.get(
      `${intellyServerUrl}admin/socket-clients`,
      intellyServerTokenConfig
    )
    return result.data.data
  } catch (err) {
    console.log(err)
  }
}

export const appService = {
  getServerLogs,
  resolveLog,
  getCustomers,
  getUser,
  getUsers,
  getAccount,
  getNodes,
  getVMs,
  getCardInfo,
  getInvoices,
  getNodeStatistics,
  getVMStatistics,
  getConnectedClients
}