import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { appService } from '../../services/app.service'
import { Dialog, Transition } from '@headlessui/react'
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from 'react-table'

import _ from 'lodash'
import { DateTime } from 'luxon'
import { BsFileEarmarkPdf } from 'react-icons/bs'

function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter)
  const onChange = (value) => {
    setFilter(value || undefined)
  }

  return (
    <div className="relative text-gray-600 focus-within:text-gray-400">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className="w-4 h-4"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <span className="flex items-center">
        <input
          name="q"
          type="search"
          className="py-1 text-sm input-sm text-slate-900 dark:text-slate-400 bg-white dark:bg-slate-900 border border-slate-300 dark:border-slate-700 w-72 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
          placeholder="Search invoices.."
          autoComplete="off"
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </span>
    </div>
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Checkbox({ indeterminate, ...rest }) {
  const ref = React.useRef()

  useEffect(() => {
    ref.current.indeterminate = indeterminate
  }, [indeterminate])

  return (
    <div className="flex">
      <input
        type="checkbox"
        ref={ref}
        {...rest}
        className="checkbox checkbox-sm checkbox-accent"
      />
    </div>
  )
}

function invoicePastDueIcon(status) {
  if (status === 'OPEN') {
    return 'badge-info'
  } else if (status === 'PAST DUE') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
    )
  } else if (status === 'PAID') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>
    )
  } else {
    return ''
  }
}

function invoicePastDue(status) {
  if (status === 'OPEN') {
    return 'Open'
  } else if (status === 'PAST DUE') {
    return 'Past due'
  } else if (status === 'PAID') {
    return 'Paid'
  } else {
    return 'Unknown'
  }
}

function invoicePastDueColor(status) {
  if (status === 'OPEN') {
    return 'text-blue-500'
  } else if (status === 'PAST DUE') {
    return 'stroke-rose-500'
  } else if (status === 'PAID') {
    return 'text-green-500'
  } else {
    return ''
  }
}

function CustomerInvoices({ customer }) {
  const [invoices, setInvoices] = useState([])

  const getInvoices = async () => {
    console.log(customer)
    const result = await appService.getInvoices(customer?.customerId)
    console.log(result)
    setInvoices(result)
  }

  useEffect(() => {
    getInvoices()
  }, [customer])

  const columns = useMemo(
    () => [
      // {
      //   id: "selection",
      //   width: "w-8 sm:w-auto",
      //   Header: ({ getToggleAllRowsSelectedProps }) => (
      //     <Checkbox {...getToggleAllRowsSelectedProps()} />
      //   ),
      //   Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
      //   canFilter: false,
      // },
      {
        Header: 'Period',
        accessor: 'period',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Created',
        accessor: 'created',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Amount',
        accessor: 'amountDue',
        width: 'w-64 sm:w-auto',
        canFilter: true,
      },
      {
        Header: 'Paid',
        accessor: 'amountPaid',
        width: 'w-64 sm:w-auto',
        canFilter: false,
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value }) => <div>{value === 1 ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Status',
        accessor: 'paid',
        width: 'w-64 sm:w-auto',
        canFilter: false,
        Cell: ({ value }) => (
          <div
            className={`${invoicePastDueColor(
              value,
            )} flex gap-2 mt-2 badge uppercase text-xs w-28`}
          >
            {invoicePastDueIcon(value)}
            {invoicePastDue(value)}
          </div>
        ),
      },
      {
        Header: 'Invoice',
        accessor: 'invoiceUrl',
        width: 'w-64 sm:w-auto',
        canFilter: true,
        Cell: ({ value }) => (
          <a
            href={value}
            target="blank"
            className="text-right text-lg text-indigo-500"
          >
            <BsFileEarmarkPdf />
          </a>
        ),
      },
    ],
    [],
  )

  const data = useMemo(() => invoices || [], [invoices])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    },
  )

  return (
    <>
      <div className="h-[calc(100%-4rem)] w-full px-4">
        <div className="flex justify-between w-full gap-4 pb-4 pt-4">
          <p className="font-semibold">Invoices</p>
          <GlobalFilter
            filter={state.globalFilter}
            setFilter={setGlobalFilter}
          />
        </div>
        <section className="h-full w-full overflow-y-auto overflow-x-auto">
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-neutral "
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className=" py-3 text-left text-sm uppercase tracking-wider sticky top-0 bg-base-100 z-10"
                    >
                      <span
                        className={`${column.width} flex items-center gap-1 text-xs`}
                      >
                        {column.render('Header')}
                        {column.id !== 'selection' &&
                          column.id !== 'options' &&
                          column.id !== 'Integrations' && (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </span>
                          )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="text-xs font-light divide-y"
            >
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      </div>
    </>
  )
}

export default CustomerInvoices
