import React, { useState, useEffect } from 'react'
import useAuth from '../../firebase/useAuth'
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth'

const EmailVerification = () => {
  const { user } = useAuth()
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState(null)
  const [isVerified, setIsVerified] = useState(false)

  const auth = getAuth()

  useEffect(() => {
    if (user) {
      setIsVerified(user.emailVerified)
    }
  }, [user])

  const sendVerificationEmail = (auth, user) => {
    sendEmailVerification(user)
      .then(() => {
        console.log('Verification email sent.')
        setEmailSent(true)
      })
      .catch((error) => {
        console.error('Error sending verification email:', error)
        setError(error.message)
      })
  }

  const checkVerificationStatus = async () => {
    await user.reload()
    setIsVerified(user.emailVerified)
  }

  return (
    <div className="mb-6">
      {!isVerified && (
        <div
          className="w-full px-4 py-3 text-sm text-pink-500 border border-pink-100 rounded bg-pink-50 mb-4"
          role="alert"
        >
          <p>Please verify your email adress</p>
        </div>
      )}
      {!isVerified && (
        <>
          <div className="flex gap-3">
            <p className="font-semibold text-sm mb-3">E-mail verification</p>
            {error && <p className="text-sm text-rose-400">{error}</p>}
          </div>
          {emailSent ? (
            <div>
              <p className="text-sm mb-2">
                Email sent. Please check your inbox and click the verification
                link.
              </p>

              <button
                onClick={checkVerificationStatus}
                className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-indigo-500 hover:bg-indigo-600 focus:bg-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-300 disabled:shadow-none"
              >
                <span> I've verified my email</span>
              </button>
            </div>
          ) : (
            <button
              onClick={() => sendVerificationEmail(auth, user)}
              className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded bg-indigo-50 px-5 text-sm font-medium tracking-wide text-indigo-500 transition duration-300 hover:bg-indigo-100 hover:text-indigo-600 focus:bg-indigo-200 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-indigo-300 disabled:bg-indigo-100 disabled:text-indigo-400 disabled:shadow-none"
            >
              <span>Send Verification Email</span>
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default EmailVerification
