import React from 'react'
import { Link } from 'react-router-dom'
import logo from './assets/logo_blue.svg'
import {
  IoHomeOutline,
  IoTimerOutline,
  IoFileTrayFullOutline,
  IoBarChartOutline,
  IoBugOutline,
  IoCalendarOutline,
  IoListCircleOutline,
  IoServerOutline,
  IoTrendingUp,
  IoMailOutline,
  IoMegaphoneOutline,
  IoSparklesOutline,
} from 'react-icons/io5'
import useLogout from '../../firebase/useLogout'

//// 1. Add sidebar items below
//// 2. Add Routes in App.jsx
//// 3. Use icons from https://react-icons.github.io/react-icons/icons?name=io5
//// 4. Add page names in AuthenticatedLayout.jsx

const links_1 = [
  { path: '/', label: 'Home', icon: <IoHomeOutline /> },
  { path: '/customers', label: 'Customers', icon: <IoFileTrayFullOutline /> },
]

const links_2 = [
  { path: '/nexus', label: 'Nexus', icon: <IoTimerOutline /> },
  { path: '/statistics', label: 'Statistics', icon: <IoBarChartOutline /> },
  { path: '/server-logs', label: 'Server Logs', icon: <IoBugOutline /> },
]
const links_3 = [
  { path: '/iframe', label: 'iFrame', icon: <IoListCircleOutline /> },
  { path: '/clients', label: 'Lorem ipsum', icon: <IoServerOutline /> },
  { path: '/clients', label: 'Lorem ipsum', icon: <IoCalendarOutline /> },
  { path: '/clients', label: 'Lorem ipsum', icon: <IoTrendingUp /> },
]
const links_4 = [
  { path: '/clients', label: 'E-mail', icon: <IoMailOutline /> },
  { path: '/clients', label: 'Notification', icon: <IoMegaphoneOutline /> },
  { path: '/clients', label: 'Feature release', icon: <IoSparklesOutline /> },
]

///////

function Sidebar() {
  const logout = useLogout()

  return (
    <div className="bg-slate-50 h-screen p-4 flex flex-col justify-between">
      <span>
        <div className="flex items-center justify-start gap-4 mb-8 px-5">
          <img className="h-8 w-8" src={logo} alt="logotype" />
          <div className="flex gap-1">
            <span
              style={{ fontFamily: 'ff-cocon-pro, sans-serif' }}
              className="text-2xl"
            >
              intelly
            </span>
            <span className="text-2xl font-thin">admin</span>
          </div>
        </div>
        <div className="space-y-1">
          <div className="px-5 font-light text-sm uppercase text-gray-600 ">
            Lorem ipsum
          </div>
          {links_1.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className="inline-flex h-10 w-full items-center justify-start gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-indigo-500 transition duration-300 hover:bg-indigo-50 hover:text-indigo-600 focus:bg-indigo-100 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-indigo-300 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="order-2 font-normal">{link.label}</span>
              <span className="text-xl">{link.icon}</span>
            </Link>
          ))}
        </div>
        <div className="space-y-1 mt-8">
          <div className="px-5 font-light text-sm uppercase text-gray-600">
            Analytics
          </div>
          {links_2.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className="inline-flex h-10 w-full items-center justify-start gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-indigo-500 transition duration-300 hover:bg-indigo-50 hover:text-indigo-600 focus:bg-indigo-100 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-indigo-300 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="order-2 font-normal">{link.label}</span>
              <span className="text-xl">{link.icon}</span>
            </Link>
          ))}
        </div>
        <div className="space-y-1 mt-8">
          <div className="px-5 font-light text-sm uppercase text-gray-600">
            Data
          </div>
          {links_3.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className="inline-flex h-10 w-full items-center justify-start gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-indigo-500 transition duration-300 hover:bg-indigo-50 hover:text-indigo-600 focus:bg-indigo-100 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-indigo-300 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="order-2 font-normal">{link.label}</span>
              <span className="text-xl">{link.icon}</span>
            </Link>
          ))}
        </div>
        <div className="space-y-1 mt-8">
          <div className="px-5 font-light text-sm uppercase text-gray-600">
            Communication
          </div>
          {links_4.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className="inline-flex h-10 w-full items-center justify-start gap-2 justify-self-center whitespace-nowrap rounded px-5 text-sm font-medium tracking-wide text-indigo-500 transition duration-300 hover:bg-indigo-50 hover:text-indigo-600 focus:bg-indigo-100 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-indigo-300 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="order-2 font-normal">{link.label}</span>
              <span className="text-xl">{link.icon}</span>
            </Link>
          ))}
        </div>
      </span>
      <button
        onClick={logout}
        className="flex items-center gap-2 px-5 mb-4 hover:text-rose-400 border-t pt-6"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
          />
        </svg>
        <span className="text-sm">Log out</span>
      </button>
    </div>
  )
}

export default Sidebar
