import React from 'react'

const ProgressBar = ({ value, max }) => {
  const percentage = ((value / max) * 100).toFixed(0)

  return (
    <div className="relative w-full">
      <label
        id={`progress-label-${value}`}
        htmlFor={`progress-${value}`}
        className="absolute top-0 left-0 mb-0 block w-1/2 text-center text-xs text-white"
      >
        {percentage}%
      </label>
      <progress
        aria-labelledby={`progress-label-${value}`}
        id={`progress-${value}`}
        max={max}
        value={value}
        className="block w-full overflow-hidden rounded bg-slate-100 [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-indigo-500 [&::-moz-progress-bar]:bg-indigo-500"
      >
        {percentage}%
      </progress>
    </div>
  )
}

export default ProgressBar
