import Homepage from './pages/home/Homepage'
import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import AuthContext from './contexts/AuthContext'
import AuthenticatedLayout from './AuthenticatedLayout'
import LoginPage from './pages/login/LoginPage'
import Customers from './pages/customers/Customers'
import useAuth from './firebase/useAuth'
import UserAccount from './pages/account/UserAccount'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Nexus from './pages/nexus/Nexus'
import ServerLogs from './pages/server-logs/ServerLogs'
import CustomerPage from './pages/customers/CustomerPage'
import UiLib from './pages/UiLib'
import NodePage from './pages/nexus/NodePage'
import Statistics from './pages/statistics/Statistics'
import toast, { Toaster } from 'react-hot-toast'
import IFrame from './pages/iFrame/IFrame.jsx'

function App() {
  const { user, loading } = useAuth()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [currentUser, setCurrentUser] = useState(null)
  const auth = getAuth()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user)
        setIsLoggedIn(true)
      } else {
        setCurrentUser(null)
      }
    })

    return () => unsubscribe()
  }, [user])

  useEffect(() => {
    if (!document.getElementById('recaptcha-container')) {
      const container = document.createElement('div')
      container.id = 'recaptcha-container'
      document.body.appendChild(container)
    }
  }, [])

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-live="polite"
          aria-busy="true"
          aria-labelledby="title-03b desc-03b"
          className="w-8 h-8 animate animate-spin"
        >
          <title id="title-03b">Icon title</title>
          <desc id="desc-03b">Some desc</desc>
          <circle
            cx="12"
            cy="12"
            r="10"
            className="stroke-slate-200"
            strokeWidth="4"
          />
          <path
            d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
            className="stroke-indigo-500"
            strokeWidth="4"
          />
        </svg>
      </div>
    )
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <div id="recaptcha-container"></div>

      <BrowserRouter>
        <Toaster position="top-right" />
        <Routes>
          <Route path="/ui" element={<UiLib />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/*"
            element={
              isLoggedIn ? <AuthenticatedLayout /> : <Navigate to="/login" />
            }
          >
            <Route index element={<Homepage />} />
            <Route path="customers" element={<Customers />} />
            <Route path="account" element={<UserAccount />} />
            <Route path="nexus" element={<Nexus />} />
            <Route path="server-logs" element={<ServerLogs />} />
            <Route path="customers/:customerId" element={<CustomerPage />} />
            <Route path="nexus/:node" element={<NodePage />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="iframe" element={<IFrame />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App
