import React, { useEffect, useState, useRef } from "react";
import ReusableInput from "../../components/ui/inputs/ReusableInput";
import ReusableSelect from "../../components/ui/inputs/ReusableSelect";
import { appService } from "../../services/app.service";

function IFrame() {
  const [loading, setLoading] = useState(true);
  const [environment, setEnvironment] = useState("production");
  const [origin, setOrigin] = useState("");
  const [frame, setFrame] = useState("");
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState("");
  const [users, setUsers] = useState([])
  const [user, setUser] = useState("")
  const iframeRef = useRef(null);

  const handleOriginChange = (value) => {
    setOrigin(value);
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      const customers = await appService.getCustomers(null, environment);
      const customersOptions = []
      customers.forEach((customer) => {
        customersOptions.push({
          label: customer.customerName,
          value: customer.customerId
        })
      })
      setCustomers(customersOptions)
    }

    fetchCustomers();
  }, [environment])

  useEffect(() => {
    if (customer) {
      console.log(customer)
      const fetchUsers = async () => {
        setUsers([])
        setUser("")
        const users = await appService.getUsers(customer, environment)
        const usersOptions = []
        users.forEach((user) => {
          usersOptions.push({
            label: user.userName,
            value: user.email
          })
        })
        setUsers(usersOptions)
      }
      fetchUsers();
    }
  }, [customer])

  useEffect(() => {
    if (origin && frame && user) {
      console.log(user)
      const iFrame = document.querySelector("iframe");

      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage({
          type: "authentication",
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...',
            email: user,
            mockedOrigin: origin
        }, frame); // Target the correct origin of the iframe
      }
    }
  }, [origin, frame, user]);

  return (
    <>
      <div className="flex flex-col h-[calc(100%-4rem)] w-full p-4">
        <div id="filters" className="flex gap-4 p-4 border-b items-center">
        <ReusableSelect
            options={[
              {
                label: "Production",
                value: "production",
              },
              {
                label: "Development",
                value: "development",
              },
            ]}
            required={true}
            labelText="Environment"
            selectedValue={environment}
            onSelectChange={(e) => {
              setEnvironment(e.target.value);
            }}
            id="environment-select"
          />
          <ReusableSelect
            options={customers}
            required={true}
            labelText="Customer"
            selectedValue={customer}
            onSelectChange={(e) => {
              setCustomer(e.target.value);
            }}
            id="customer-select"
          />
          <ReusableSelect
            options={users}
            required={true}
            labelText="User"
            selectedValue={user}
            onSelectChange={(e) => {
              setUser(e.target.value);
            }}
            id="user-select"
          />
          <ReusableSelect
            options={[
              {
                label: "GenesysCloud",
                value: "https://apps.mypurecloud.ie/",
              },
              {
                label: "C1",
                value: "https://app.customerfirst.se",
              },
            ]}
            required={true}
            labelText="Origin"
            selectedValue={origin}
            onSelectChange={(e) => {
              handleOriginChange(e.target.value);
            }}
            id="origin-select"
          />
          <ReusableInput
            id="frame-input"
            placeholder="Frame URL"
            helperText="Enter the iFrame URL"
            value={frame}
            onChange={(e) => {
              setFrame(e.target.value);
            }}
          />
          
        </div>
        <div className="flex flex-col w-full h-full gap-2 px-4 py-2">
          <iframe ref={iframeRef} src={frame} width="100%" height="100%"></iframe>
        </div>
      </div>
    </>
  );
}

export default IFrame;
