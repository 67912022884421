import React, { useState, useRef, useEffect } from 'react'

export default function ReusableTabs({ tabs }) {
  const [tabSelected, setTabSelected] = useState({
    currentTab: 1,
    noTabs: tabs.length,
  })

  const wrapperRef = useRef(null)

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      // Right arrow
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (
          tabSelected.currentTab >= 1 &&
          tabSelected.currentTab < tabSelected.noTabs
        ) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab + 1,
          })
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: 1,
          })
        }
      }
    }

    if (e.keyCode === 37) {
      // Left arrow
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (
          tabSelected.currentTab > 1 &&
          tabSelected.currentTab <= tabSelected.noTabs
        ) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab - 1,
          })
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.noTabs,
          })
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [tabSelected])

  return (
    <>
      <section className="max-w-full" aria-multiselectable="false">
        <ul className="flex items-center gap-2" role="tablist" ref={wrapperRef}>
          {tabs.map((tab, index) => (
            <li key={index} className="" role="presentation">
              <button
                className={`inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded px-6 text-sm font-medium tracking-wide transition duration-300 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tabSelected.currentTab === index + 1
                    ? 'bg-indigo-500 text-white hover:bg-indigo-600 focus:bg-indigo-700 disabled:bg-indigo-300'
                    : 'w-full justify-self-center stroke-slate-700 text-slate-700 hover:bg-indigo-50 hover:stroke-indigo-500 hover:text-indigo-500 focus:bg-indigo-50 focus:stroke-indigo-600 focus:text-indigo-600 disabled:text-indigo-300'
                }`}
                role="tab"
                aria-setsize={tabs.length}
                aria-posinset={index + 1}
                tabIndex={`${
                  tabSelected.currentTab === index + 1 ? '0' : '-1'
                }`}
                aria-controls={`tab-panel-${index}`}
                aria-selected={`${
                  tabSelected.currentTab === index + 1 ? 'true' : 'false'
                }`}
                onClick={() =>
                  setTabSelected({ ...tabSelected, currentTab: index + 1 })
                }
              >
                <span>{tab.label}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`px-6 py-4 ${
                tabSelected.currentTab === index + 1 ? '' : 'hidden'
              }`}
              id={`tab-panel-${index}`}
              role="tabpanel"
              aria-labelledby={`tab-label-${index}`}
              tabIndex="-1"
            >
              <p>{tab.content}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
