import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { DateTime } from 'luxon'
import Chart from 'react-apexcharts'

function GaugeWidget({
  value,
  dataLabel,
  title,
  threshold,
  belowThresholdColor,
  aboveThresholdColor,
}) {
  const chartRef = useRef(null)
  const containerRef = useRef(null)

  const options = {
    chart: {
      type: 'radialBar',
      offsetY: 0,
      width: '130%',
    },
    stroke: {
      lineCap: 'round',
    },
    title: {
      text: title,
      align: 'center',
      offsetY: 20,
      style: {
        fontSize: '22px',
        fontWeight: 'semi-bold',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
    },

    plotOptions: {
      radialBar: {
        offsetY: 25,
        startAngle: -100,
        endAngle: 100,
        track: {
          show: true,
          background: '#cbd5e1',
          strokeWidth: '130%',
          opacity: 1,
          margin: -30,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 1,
          },
        },

        hollow: {
          margin: 0,
          size: '100%',
        },

        dataLabels: {
          name: {
            show: true,
            formatter: function () {
              return `${value[0].toFixed(2)}%`
            },
            style: {
              fontSize: 40,
              fontWeight: 'semi-bold',
              fontFamily: 'Helvetica, Arial, sans-serif',
            },
            offsetY: -10,
          },
          value: {
            color: '#555555',
            fontSize: 12,
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            show: true,
            position: 'middle',
            formatter: function (val) {
              if (dataLabel) {
                return dataLabel
              } else {
                return `${val.toFixed(2)}%`
              }
            },
            offsetY: 3,
          },
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    fill: {
      colors: [
        function ({ value, seriesIndex, w }) {
          if (!threshold || value < threshold) {
            return belowThresholdColor.hex
          } else {
            return aboveThresholdColor.hex
          }
        },
      ],
    },
  }

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.updateOptions(options, false)
    }
  }, [options])

  return (
    <>
      <div
        ref={containerRef}
        className={`flex h-full flex-col items-center justify-center`}
      >
        <div className={`flex w-full h-full`}>
          <Chart
            options={options}
            series={value}
            type="radialBar"
            height="100%"
            width="100%"
            ref={chartRef}
          />
        </div>
      </div>
    </>
  )
}

export default GaugeWidget
