import React, { useRef, useState } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import ReusableButton from '../components/ui/buttons/ReusableButton'
import ReusableToggle from '../components/ui/toggles/ReusableToggle'
import ReusableModal from '../components/ui/modals/ReusableModal'
import ReusableTabs from '../components/ui/tabs/ReusableTabs'
import ReusableInput from '../components/ui/inputs/ReusableInput'
import ReusableDropdown from '../components/ui/dropdowns/ReusableDropdown'
import ReusableSpinner from '../components/ui/loading/ReusableSpinner'
import ProgressBar from '../components/ui/loading/ProgressBar'
import ReusableAlert from '../components/ui/alerts/ReusableAlert'
import { useNotification } from '../hooks/useNotification'
import ReusableRadioBtnGroup from '../components/ui/inputs/ReusableRadioBtnGroup'
import ReusableSelect from '../components/ui/inputs/ReusableSelect'
import ReusableCheckbox from '../components/ui/inputs/ReusableCheckbox'
import ReusableCheckboxDropdown from '../components/ui/dropdowns/ReusableCheckboxDropdown'
import ReusableTextInput from '../components/ui/inputs/ReusableTextInput'


const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
}

const UiLib = () => {
  const buttonRef = useRef(null)
  const toggleRef = useRef(null)
  const modalRef = useRef(null)
  const tabsRef = useRef(null)
  const inputRef = useRef(null)
  const dropdownRef = useRef(null)
  const checkboxDropdownRef = useRef(null)
  const spinnerRef = useRef(null)
  const progressBarRef = useRef(null)
  const alertRef = useRef(null)
  const notificationRef = useRef(null)
  const radioRef = useRef(null)
  const selectRef = useRef(null)
  const checkboxRef = useRef(null)
  const textInput = useRef(null)

  const {
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyCustom,
    notifyWarning,
  } = useNotification()

  const buttonCodeString = `
<ReusableButton
    type="primary" // primary | secondary | outline
    label="Click me" // string
    size="small" // small  (optional)
    loading={true} // boolean (optional)
    disabled={true} // boolean (optional)
    onClick={() => console.log('clicked')} // pass a function
/>
  `

  const toggleCodeString = `

const [toggled, setToggled] = useState(true)

<ReusableToggle
    id="toggle1"  // unique string
    isChecked={toggled} // boolean
    label="Show logs" // string  (optional)
    onChange={() => setToggled(!toggled)}   // pass a function
    disabled={false}    // boolean (optional)
/>
    `
  const [toggled, setToggled] = useState(true)

  const modalCodeString = `

  ////// Only if you want to open modal from parent component //////
  const [modalOpen, setModalOpen] = useState(false)   // put this in parent component if you want to open modal from parent component instead of modal button

  const handleOpenModal = () => {           // pass this function to button onClick to open modal
    setModalOpen(true)
  }
  const handleCloseModal = () => {          // pass this function to modal onClose to close modal
    setModalOpen(false)
  }
  //////////////////////////////////////////////////////////////////

<ReusableModal
    title="Log Information"         // string - title of modal
    renderButton={true}             // boolean - render button to open modal
    openButtonLabel="Open"          // string - label for open button if renderButton is true
    externalOpen={modalOpen}        // boolean - open modal from parent component
    onClose={handleCloseModal}      // function - pass a function to handle close modal
    allowCloseOnButton={true}       // boolean - allow close modal on button click (optional)
    allowCloseOnOverlay={true}      // boolean - allow close modal on overlay click (optional)
>
    {({ closeModal }) => (          // body of modal
    <div className="flex flex-col gap-4 text-sm"> 
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde
        quibusdam earum voluptatibus, magnam odio, officia tenetur nemo
        ratione illo quaerat dicta ab similique nostrum nobis voluptates
        nihil autem eligendi ea!
        <ReusableButton label="Close" onClick={closeModal} />
    </div>
    )}
</ReusableModal>
    `

  const TabsCodeString = `

  const tabsData = [
    {
      label: 'Tab 1',
      content: 'Content for Tab 1',
    },
    {
      label: 'Tab 2',
      content: (
        <div className="bg-slate-200 p-6">Content for Tab 3</div>
      ),
    },
    {
      label: 'Tab 3',
      content: <div className="bg-slate-200 p-6">Content for Tab 3</div>,
    },
  ]

  <ReusableTabs tabs={tabsData} />
      `

  const tabsData = [
    {
      label: 'Tab 1',
      content: 'Content for Tab 1',
    },
    {
      label: 'Tab 2',
      content: (
        <div className="w-auto h-fit">
          <img
            className="w-auto h-24"
            src="https://images.unsplash.com/photo-1682695795255-b236b1f1267d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3540&q=80"
          />
        </div>
      ),
    },
    {
      label: 'Tab 3',
      content: <div className="bg-slate-200 p-6">Content for Tab 3</div>,
    },
  ]

  const InputCodeString = `
  <ReusableInput
    id="name-input"         // unique string
    placeholder="Name"      // string (optional)
    helperText="Your name"  // string (optional) - label under input
    value={value}           // string (optional) - value of input
    onChange={(e) => console.log(e.target.value)}   // pass a function
/>
    `

  const DropdownCodeString = `
    const options = [
        {
          label: 'Edit',
          icon: () => (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M17 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM17 15.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM3.75 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zM4.5 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM10 11a.75.75 0 01.75.75v5.5a.75.75 0 01-1.5 0v-5.5A.75.75 0 0110 11zM10.75 2.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM10 6a2 2 0 100 4 2 2 0 000-4zM3.75 10a2 2 0 100 4 2 2 0 000-4zM16.25 10a2 2 0 100 4 2 2 0 000-4z" />
              </svg>
            </div>
          ),
        },
        // ... other options
      ]

    <ReusableDropdown 
        type="kebab"            // kebab | default (optional)
        label="Nice menu"       // string (optional)
        options={options}       // array of objects
    />    
    `
  const options = [
    {
      label: 'Edit',
      icon: () => (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path d="M17 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM17 15.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM3.75 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zM4.5 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM10 11a.75.75 0 01.75.75v5.5a.75.75 0 01-1.5 0v-5.5A.75.75 0 0110 11zM10.75 2.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM10 6a2 2 0 100 4 2 2 0 000-4zM3.75 10a2 2 0 100 4 2 2 0 000-4zM16.25 10a2 2 0 100 4 2 2 0 000-4z" />
          </svg>
        </div>
      ),
    },
    {
      label: 'Settings',
      icon: () => (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path d="M17 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM17 15.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM3.75 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zM4.5 2.75a.75.75 0 00-1.5 0v5.5a.75.75 0 001.5 0v-5.5zM10 11a.75.75 0 01.75.75v5.5a.75.75 0 01-1.5 0v-5.5A.75.75 0 0110 11zM10.75 2.75a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5zM10 6a2 2 0 100 4 2 2 0 000-4zM3.75 10a2 2 0 100 4 2 2 0 000-4zM16.25 10a2 2 0 100 4 2 2 0 000-4z" />
          </svg>
        </div>
      ),
    },
    // ... other options
  ]

  const CheckboxDropdownCodeString = `
  import ReusableCheckboxDropdown from '../../components/ui/dropdowns/ReusableCheckboxDropdown'

  
    const checkboxOptions = [
      { key: 'option1', label: 'Option 1' },
      { key: 'option2', label: 'Option 2' },
      { key: 'option3', label: 'Option 3' },
      // ... Add as many as you want
    ]
  
    const handleSelect = (selectedOptions) => {
      console.log('Selected options: ', selectedOptions)
      // Do something with the selectedOptions
    }
  


    <ReusableCheckboxDropdown
        label="Choose Options"          // string (optional)
        options={checkboxOptions}       // array of objects
        onSelect={handleSelect}         // pass a function
        type="kebab"                    // kebab (optional)
    />
     

    `
  const checkboxOptions = [
    { key: 'option1', label: 'Option 1' },
    { key: 'option2', label: 'Option 2' },
    { key: 'option3', label: 'Option 3' },
    // ... Add as many as you want
  ]
  const handleSelect = (selectedOptions) => {
    // console.log('Selected options: ', selectedOptions)
    // Do something with the selectedOptions
  }

  const SpinnerCodeString = `
  <ReusableSpinner size="xs" />     // xs | small | medium | large (optional)
  `

  const ProgressBarCodeString = `
  <ProgressBar value={34} max={100} />      // value - number (required), max - number (required)
  `


  const AlertCodeString = `
  <Alert
  type="success"    // success | warning | info | danger
  title="Lorem ipsum dolor sit amet"    // string (optional)
  content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."  // string 
  />
  `

  const NotificationCodeString = `
import { useNotification } from '../../hooks/useNotification'  // import hook

function App() {

const {                // use hook
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyCustom,
    notifyWarning,
    } = useNotification()

return (
    <div className="space-x-1">

    <ReusableButton             // notification trigger example
    type="primary"
    label="Success"
    onClick={() =>
      notifySuccess('Success!', 'Lorem ipsum dolor sit amet')
    }
    />
  
    <ReusableButton
    type="primary"
    label="Custom"
    size="small"
    onClick={() =>        // custom JSX notification example
      notifyCustom(
        <div className="flex justify-center items-center w-96 h-24 rounded-2xl bg-sky-300 text-blue-500">
          Hello TailwindCSS! 👋
        </div>,
      )
    } />

    </div>
  )
}

export default App

  `
  const RadioBtnCodeString = `
import ReusableRadioBtnGroup from '../../components/ui/inputs/ReusableRadioBtnGroup'

function App() {
  const options = [                             // pass this array of objects to RadioBtnGroup options prop
    { id: 'value1', value: 'value1', label: 'First' },
    { id: 'value2', value: 'value2', label: 'Second' },
    { id: 'value3', value: 'value3', label: 'Third' },
  ]

  const handleValueChange = (value) => {        // pass this function to RadioBtnGroup onValueChange
    console.log(value)
  }

  return (
    <div className="h-[calc(100%-4rem)] space-x-1 w-full p-4">
      <ReusableRadioBtnGroup
        options={options}                       // array of objects
        groupName="values"                      // string
        onValueChange={handleValueChange}       // pass a function
        legendText="Choose a value"             // string (optional)
        layout="horizontal"                     // horizontal | vertical (optional)
      />
    </div>
  )
}

export default App
  `

  const radioOptions = [
    { id: 'value1', value: 'value1', label: 'First' },
    { id: 'value2', value: 'value2', label: 'Second' },
    { id: 'value3', value: 'value3', label: 'Third' },
  ]

  const handleValueChange = (value) => {
    console.log(`Selected value: ${value}`)
  }

  const SelectCodeString = `
    import React, { useState } from 'react'
    import ReusableSelect from '../../components/ui/inputs/ReusableSelect'
    
    function App() {
      const options = [
        { value: 'value1', label: 'First' },
        { value: 'value2', label: 'Second' },
        { value: 'value3', label: 'Third' },
      ]
    
      const handleValueChange = (value) => {
        console.log(value)
      }
      return (
        <div className="h-[calc(100%-4rem)] space-x-1 w-full p-4">
          <ReusableSelect
            options={options}                   // Options should be an array of objects { label: 'Option 1', value: 1 }
            required={true}                     // boolean (optional)
            labelText="Select a value"          // string (optional)
            selectedValue={options[0].value}    // if you want to pass default value (optional)
            onSelectChange={(e) => {            // pass a function
              handleValueChange(e.target.value)
            }}
            id="client-select1"                   // unique string (optional)
          />
        </div>
      )
    }
    
    export default App
    `

  const selectOptions = [
    { value: 'value1', label: 'First' },
    { value: 'value2', label: 'Second' },
    { value: 'value3', label: 'Third' },
  ]

  const CheckboxCodeString = `
    import React, { useState } from 'react'
    import ReusableSelect from '../../components/ui/inputs/ReusableSelect'
    
    function App() {

      const [state, setState] = useState(false)
    
      return (
        <div className="h-[calc(100%-4rem)] space-x-1 w-full p-4">


        <ReusableCheckbox
            label="Accept Terms and Conditions" // string (optional)
            id='terms-checkbox'                 // unique string
            disabled={false}                    // boolean (optional)
            checked={state}                     // pass checked value
            onChange={(newCheckedState) => {    
                console.log(newCheckedState)
                                                // Do something when the checkbox state changes
              }}
        />

        </div>
      )
    }
    
    export default App
    `

  const TextInputString = `
 
      const [textAreaValue, setTextAreaValue] = useState('')

      const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value)
      }


    <ReusableTextInput
      id="customId"                   // unique string
      placeholder="Placeholder text"  // string (optional)
      rows={5}                        // number (optional)
      value={textAreaValue}           // string (optional)
      onChange={handleTextAreaChange} // pass a function
    />

    `
  const [textAreaValue, setTextAreaValue] = useState('')

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value)
  }


  return (
    <div className="p-8">
      <h1 className="text-3xl mb-4 font-thin">
        <span
          className="font-normal"
          style={{ fontFamily: 'ff-cocon-pro, sans-serif' }}
        >
          intelly
        </span>{' '}
        admin Components
      </h1>

      <div className="grid grid-cols-2 gap-4">
        {/* Button Demo */}
        <div className="border p-4 space-x-2">
          <h2 className="text-xl mb-2">Button</h2>
          <ReusableButton
            label="Primary"
            type="primary"
            size="small"
            onClick={() => console.log('clicked')}
          />
          <ReusableButton
            label="Secondary"
            type="secondary"
            size="small"
            onClick={() => console.log('clicked')}
          />
          <ReusableButton
            label="Outline"
            type="outline"
            size="small"
            onClick={() => console.log('clicked')}
          />
          <ReusableButton
            label="Primary"
            type="primary"
            size="small"
            loading={true}
            onClick={() => console.log('clicked')}
          />
          <ReusableButton
            label="Disabled"
            type="primary"
            size="small"
            disabled={true}
            onClick={() => console.log('clicked')}
          />
          <div className="mt-2">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={buttonRef}
            >
              {buttonCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(buttonCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Toggle Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Toggle</h2>
          <ReusableToggle
            id="toggle1"
            label="Show logs"
            isChecked={toggled}
            disabled={false}
            onChange={() => setToggled(!toggled)}
          />
          <div className="mt-2">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={toggleRef}
            >
              {toggleCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(toggleCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Modal Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Modal</h2>
          <ReusableModal
            title="Log Information"
            openButtonLabel="Open"
            renderButton={true}
            // externalOpen={modalOpen}
            // onClose={handleCloseModal}
            allowCloseOnButton={true}
            allowCloseOnOverlay={true}
          >
            {({ closeModal }) => (
              <div className="flex flex-col gap-4 text-sm">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde
                quibusdam earum voluptatibus, magnam odio, officia tenetur nemo
                ratione illo quaerat dicta ab similique nostrum nobis voluptates
                nihil autem eligendi ea!
                <ReusableButton label="Close" onClick={closeModal} />
              </div>
            )}
          </ReusableModal>
          <div className="mt-2">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={modalRef}
            >
              {modalCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(modalCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Tabs Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Tabs</h2>
          <ReusableTabs tabs={tabsData} />
          <div className="mt-2">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={tabsRef}
            >
              {TabsCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(TabsCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Input Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Input</h2>
          <ReusableInput
            id="name-input"
            placeholder="Name"
            helperText="Your name"
            onChange={(e) => console.log(e.target.value)}
          />
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={inputRef}
            >
              {InputCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(InputCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Menu Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Dropdown</h2>
          <div className="flex items-center gap-4">
            <ReusableDropdown label="Nice menu" options={options} />
            <ReusableDropdown type="kebab" options={options} />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={dropdownRef}
            >
              {DropdownCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(DropdownCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Spinner Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Spinner</h2>
          <div className="flex items-center gap-4">
            <ReusableSpinner size="xs" />
            <ReusableSpinner size="small" />
            <ReusableSpinner size="medium" />
            <ReusableSpinner size="large" />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={spinnerRef}
            >
              {SpinnerCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(SpinnerCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>


        {/* CheckboxMenu Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Multiselect dropdown</h2>
          <div className="flex items-center gap-4">
            <ReusableCheckboxDropdown
              label="Choose options"
              options={checkboxOptions}
              onSelect={handleSelect}
            />
            <ReusableCheckboxDropdown
              label="Choose options"
              options={checkboxOptions}
              onSelect={handleSelect}
              type="kebab"
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={checkboxDropdownRef}
            >
              {CheckboxDropdownCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(CheckboxDropdownCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>


        {/* Progress bar Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Progress bar</h2>
          <div className="flex items-center gap-4">
            <ProgressBar value={34} max={100} />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}

              ref={progressBarRef}

            >
              {ProgressBarCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(ProgressBarCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>


        {/* Alert Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Alerts</h2>
          <div className="flex flex-col items-center gap-4">
            <ReusableAlert
              type="success"
              title="Lorem ipsum dolor sit amet"
              content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            />
            <ReusableAlert
              type="warning"
              title="Lorem ipsum dolor sit amet!"
              content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            />
            <ReusableAlert
              type="info"
              title="Lorem ipsum dolor sit amet"
              content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            />
            <ReusableAlert
              type="danger"
              title="Lorem ipsum dolor sit amet"
              content="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={alertRef}
            >
              {AlertCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(AlertCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Notification Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Notifications</h2>
          <div className="flex items-center gap-4">
            <ReusableButton
              type="primary"
              label="Success"
              size="small"
              onClick={() =>
                notifySuccess('Success!', 'Lorem ipsum dolor sit amet')
              }
            />
            <ReusableButton
              type="primary"
              label="Error"
              size="small"
              onClick={() =>
                notifyError('An error occurred!', 'Lorem ipsum dolor sit amet')
              }
            />
            <ReusableButton
              type="primary"
              label="Warning"
              size="small"
              onClick={() =>
                notifyWarning('Warning!', 'Lorem ipsum dolor sit amet')
              }
            />
            <ReusableButton
              type="primary"
              label="Info"
              size="small"
              onClick={() => notifyInfo('Info!', 'Lorem ipsum dolor sit amet')}
            />
            <ReusableButton
              type="primary"
              label="Custom"
              size="small"
              onClick={() =>
                notifyCustom(
                  <div className="flex justify-center items-center w-96 h-24 rounded-2xl bg-sky-300 text-blue-500">
                    Hello TailwindCSS! 👋
                  </div>,
                )
              }
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={notificationRef}
            >
              {NotificationCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(NotificationCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Radio buttons Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Radio buttons</h2>
          <div className="flex items-center gap-4">
            <ReusableRadioBtnGroup
              options={radioOptions}
              groupName="values"
              onValueChange={handleValueChange}
              layout="horizontal"
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={radioRef}
            >
              {RadioBtnCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(RadioBtnCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Select Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Select</h2>
          <div className="flex items-center gap-4">
            <ReusableSelect
              options={selectOptions}
              required={true}
              labelText="Select a value"
              selectedValue={options[0].value}
              onSelectChange={(e) => {
                handleValueChange(e.target.value)
              }}
              id="drone-select"
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={selectRef}
            >
              {SelectCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(SelectCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Checkbox Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Checkbox</h2>
          <div className="flex items-center gap-4">
            <ReusableCheckbox
              label="Accept Terms and Conditions"
              id="terms-checkbox"
              disabled={false}
              checked={false}
              onChange={(newCheckedState) => {
                console.log(newCheckedState)
              }}
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={checkboxRef}
            >
              {CheckboxCodeString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(CheckboxCodeString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

        {/* Textinput Demo */}
        <div className="border p-4">
          <h2 className="text-xl mb-2">Text input</h2>
          <div className="">
            <ReusableTextInput
              id="customId"
              placeholder="Placeholder text"
              rows={5}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
          </div>
          <div className="mt-4">
            <SyntaxHighlighter
              language="jsx"
              style={oneDark}
              wrapLines={true}
              ref={checkboxRef}
            >
              {TextInputString}
            </SyntaxHighlighter>
            <button
              onClick={() => copyToClipboard(TextInputString)}
              className="mt-2 bg-green-500 text-white p-2 rounded"
            >
              Copy
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default UiLib
