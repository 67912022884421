import React, { useEffect, useState } from 'react'
import { Outlet, Routes, Route } from 'react-router-dom'
import Header from './components/header/Header'
import Sidebar from './components/sidebar/Sidebar'
import { useLocation } from 'react-router-dom'
import { appService } from './services/app.service'

function AuthenticatedLayout() {
  const [pageName, setPageName] = useState('')
  /// Add name for each page below, will appear in the header

  const location = useLocation()
  const pattern = /\/(customers|nexus)\/(\d+)/

  async function setNameOfPage() {
    if (
      location.pathname.includes('/customers/') ||
      location.pathname.includes('/nexus/')
    ) {
      // console.log(location.pathname)
      const parts = location.pathname.split('/')
      // console.log(parts)
      // console.log(parts.length)
      const path = parts[parts.length - 2]
      // console.log(path)
      let name
      if (path === 'customers') {
        const customer = await appService.getCustomers(
          location.pathname.match(parts[parts.length - 1]),
        )
        name = customer.data.customerName
      } else {
        name = path.charAt(0).toUpperCase() + path.slice(1)
      }
      setPageName(name)
    } else {
      switch (location.pathname) {
        case '/':
          setPageName('Home')
          break
        case '/customers':
          setPageName('Customers')
          break
        case '/account':
          setPageName('Account settings')
          break
        case '/nexus':
          setPageName('Nexus')
          break
        case '/server-logs':
          setPageName('Server Logs')
          break
        case '/statistics':
          setPageName('Statistics')
          break
        default:
          setPageName('Unknown')
      }
    }
  }

  useEffect(() => {
    setNameOfPage()
  }, [location.pathname])

  return (
    <>
      <div className="grid grid-cols-1  md:grid-cols-[16em,auto]">
        <Sidebar />
        <div className="h-screen">
          <Header pageName={pageName} />
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default AuthenticatedLayout
