import { useState, useEffect } from 'react'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  PhoneAuthProvider,
} from 'firebase/auth'

const auth = getAuth()

function useAuth() {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [resolver, setResolver] = useState(null)
  const [isMfaEnabled, setIsMfaEnabled] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  const handleSignIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      )
      setUser(userCredential.user)
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const mfaResolver = getMultiFactorResolver(auth, error)
        setResolver(mfaResolver)
      }
    }
  }

  const handleSecondFactor = async (selectedIndex, verificationCode) => {
    if (!resolver) return

    const hint = resolver.hints[selectedIndex]
    const phoneInfoOptions = {
      multiFactorHint: hint,
      session: resolver.session,
    }

    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response) {
          return response
        },
      },
      auth,
    )
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier,
    )
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    try {
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
      setUser(userCredential.user)
      setResolver(null)
    } catch (error) {}
  }

  return {
    user,
    loading,
    handleSignIn,
    handleSecondFactor,
    isMfaEnabled,
  }
}

export default useAuth
