import { useCallback } from 'react'
import toast from 'react-hot-toast'

export function useNotification() {
  const notifySuccess = useCallback((title, message) => {
    toast.custom((t) => (
      <div
        className={` ${
          t.visible ? 'animate-enter' : 'animate-leave'
        } flex w-80 max-w-full flex-col overflow-hidden rounded bg-emerald-100 px-4 py-3 text-sm text-emerald-500 shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-200`}
        role="status"
      >
        {/*  <!-- Heading with close button --> */}
        <div className="mb-2 flex items-center gap-4">
          {/*    <!-- Headline --> */}
          <h3 className="flex-1 font-semibold">{title}</h3>
          {/*    <!-- Close button --> */}
          <button
            aria-label="Close"
            onClick={() => toast.dismiss(t.id)}
            className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-30 desc-30"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*  <!-- Body --> */}
        <div className="text-emerald-500">
          <p>{message}</p>
        </div>
      </div>
    ))
  }, [])

  const notifyError = useCallback((title, message) => {
    toast.custom((t) => (
      <div
        className={` ${
          t.visible ? 'animate-enter' : 'animate-leave'
        } flex w-80 max-w-full flex-col overflow-hidden rounded bg-pink-100 px-4 py-3 text-sm text-pink-500 shadow-lg shadow-pink-400/20 ring-1 ring-inset ring-pink-200`}
        role="status"
      >
        {/*  <!-- Heading with close button --> */}
        <div className="mb-2 flex items-center gap-4">
          {/*    <!-- Headline --> */}
          <h3 className="flex-1 font-semibold">{title}</h3>
          {/*    <!-- Close button --> */}
          <button
            aria-label="Close"
            onClick={() => toast.dismiss(t.id)}
            className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-pink-500 transition duration-300 hover:bg-pink-50 hover:text-pink-600 focus:bg-pink-100 focus:text-pink-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-pink-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-30 desc-30"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*  <!-- Body --> */}
        <div className="text-pink-500">
          <p>{message}</p>
        </div>
      </div>
    ))
  }, [])

  const notifyWarning = useCallback((title, message) => {
    toast.custom((t) => (
      <div
        className={` ${
          t.visible ? 'animate-enter' : 'animate-leave'
        } flex w-80 max-w-full flex-col overflow-hidden rounded bg-amber-100 px-4 py-3 text-sm text-amber-500 shadow-lg shadow-amber-400/20 ring-1 ring-inset ring-amber-200`}
        role="status"
      >
        {/*  <!-- Heading with close button --> */}
        <div className="mb-2 flex items-center gap-4">
          {/*    <!-- Headline --> */}
          <h3 className="flex-1 font-semibold">{title}</h3>
          {/*    <!-- Close button --> */}
          <button
            aria-label="Close"
            onClick={() => toast.dismiss(t.id)}
            className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-amber-500 transition duration-300 hover:bg-amber-50 hover:text-amber-600 focus:bg-amber-100 focus:text-amber-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-amber-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-30 desc-30"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*  <!-- Body --> */}
        <div className="text-amber-500">
          <p>{message}</p>
        </div>
      </div>
    ))
  }, [])

  const notifyInfo = useCallback((title, message) => {
    toast.custom((t) => (
      <div
        className={` ${
          t.visible ? 'animate-enter' : 'animate-leave'
        } flex w-80 max-w-full flex-col overflow-hidden rounded bg-indigo-500 px-4 py-3 text-sm text-white shadow-lg shadow-indigo-400/20 ring-1 ring-inset ring-indigo-600`}
        role="status"
      >
        {/*  <!-- Heading with close button --> */}
        <div className="mb-2 flex items-center gap-4">
          {/*    <!-- Headline --> */}
          <h3 className="flex-1 font-semibold">{title}</h3>
          {/*    <!-- Close button --> */}
          <button
            aria-label="Close"
            onClick={() => toast.dismiss(t.id)}
            className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-indigo-50 hover:text-indigo-600 focus:bg-indigo-100 focus:text-indigo-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-indigo-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-30 desc-30"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*  <!-- Body --> */}
        <div className="text-indigo-100">
          <p>{message}</p>
        </div>
      </div>
    ))
  }, [])

  const notifyCustom = useCallback((customJSX) => {
    toast.custom((t) => <>{customJSX}</>)
  }, [])

  return {
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyCustom,
    notifyWarning,
  }
}
