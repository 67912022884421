import React from 'react'

const ReusableSpinner = ({ size = 'medium' }) => {
  let dimensions = 'w-6 h-6' // Default

  if (size === 'xs') dimensions = 'w-4 h-4'
  if (size === 'small') dimensions = 'w-6 h-6'
  if (size === 'medium') dimensions = 'w-8 h-8'
  if (size === 'large') dimensions = 'w-10 h-10'

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-live="polite"
      aria-busy="true"
      aria-labelledby="spinner-title spinner-desc"
      className={`${dimensions} animate-spin`}
    >
      <title id="spinner-title">Loading Spinner</title>
      <desc id="spinner-desc">A spinning circle indicating loading</desc>
      <circle
        cx="12"
        cy="12"
        r="10"
        className="stroke-slate-200"
        strokeWidth="4"
      />
      <path
        d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
        className="stroke-indigo-500"
        strokeWidth="4"
      />
    </svg>
  )
}

export default ReusableSpinner
