import React from 'react'
import EmailVerification from './EmailVerification'
import ActivateMFA from './ActivateMFA'

function UserAccount() {
  return (
    <div className="p-4">
      <EmailVerification />
      <ActivateMFA />
    </div>
  )
}

export default UserAccount
