import React, { useState } from 'react'
import ProgressBar from '../../components/ui/loading/ProgressBar'

function Homepage() {

  return <div className="h-[calc(100%-4rem)] space-x-1 w-full p-4"></div>

}

export default Homepage
